import React, {useState} from 'react';
import { useHistory } from 'react-router';
import { Intro } from './Intro';
import { ConnectToDubber } from './ConnectToDubber';
import { ConnectToTeams } from './ConnectToTeams';
import axios from "axios";
import { Ring } from "react-awesome-spinners";
import {AlertModal} from "./AlertModal";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = ({
            portal: "",
            authId: "",
            authToken: "",
            portalCode: "",
            accessToken: "",
            accountSlug: "",
            users: [],
            stepIntroVisible: true,
            step1visible: false,
            step2visible: false,
            step3visible: false,
            tenantId: "",
            domainName: "",
            botA_id: "",
            botB_id: "",
            convenienceBot: "",
            chatRecordingAppId: "",
            loading: false,
            showAlertModal: false,
            alertModalText: ''
        });

        this.onIntroNextClicked = this.onIntroNextClicked.bind(this);
        this.cancelStep1 = this.cancelStep1.bind(this);
        this.cancelStep2 = this.cancelStep2.bind(this);
        this.portalVerified = this.portalVerified.bind(this);
        this.tenantIdEntered = this.tenantIdEntered.bind(this);
        this.impersonateUserAndLogin = this.impersonateUserAndLogin.bind(this);
        this.doLogin = this.doLogin.bind(this);
    }

    onIntroNextClicked(event) {
        event.preventDefault();
        this.setState({ stepIntroVisible: false, step1visible: true });
    }

    cancelStep1(event) {
        event.preventDefault();
        this.setState({ stepIntroVisible: true, step1visible: false, showAlertModal: false });
    }

    cancelStep2(event) {
        event.preventDefault();
        this.setState({ step1visible: true, step2visible: false, showAlertModal: false });
    }

    portalVerified(portalCode, accountSlug, authId, authToken, botA, botB, accessToken, accountType, accounts, chatRecordingAppId, adSyncAppId, convenienceBot) {
        this.setState({
            portalCode: portalCode,
            accountSlug: accountSlug,
            authId: authId,
            authToken: authToken,
            step1visible: false,
            step2visible: true,
            botA_id: botA,
            botB_id: botB,
            convenienceBot: convenienceBot,
            accessToken: accessToken,
            accountType: accountType,
            accounts: accounts,
            chatRecordingAppId: chatRecordingAppId,
            adSyncAppId: adSyncAppId
        });
    }
    
    impersonateUserAndLogin(tenantId, domainName, accountSlug){
        if (accountSlug !== null) {
            let groupSlug = this.state.accountSlug;
            
            axios.get(this.props.impersonateChildAccountUrl +
                "?groupSlug=" +
                this.state.accountSlug +
                "&accountSlug=" +
                accountSlug +
                "&region=" +
                this.state.portalCode,
                {
                    headers: {
                        'Authorization': 'Jwt ' + this.state.accessToken.accessToken
                    }
                }).then((taskResponse) => {
                    var impersonateJwt = taskResponse.data;
                    if (impersonateJwt !== undefined) {
                        this.setState({
                            accountSlug: accountSlug,
                            impersonateJwt: impersonateJwt
                        });
                    }
                    this.doLogin(tenantId, domainName, accountSlug, impersonateJwt, groupSlug);
            });
        }
    }

    tenantIdEntered(tenantId, domainName, accountSlug) {
        this.setState({
            tenantId: tenantId,
            domainName: domainName,
            loading: true
        });

        if (accountSlug !== "" && accountSlug !== this.state.accountSlug) {
            this.impersonateUserAndLogin(tenantId, domainName, accountSlug);
        } else {
            this.doLogin(tenantId, domainName, accountSlug, this.state.accessToken, this.state.accountSlug);
        }
    }

    doLogin(tenantId, domainName, accountSlug, jwt, originalSlug) {
        this.setState({
            showAlertModal: false,
        })
        
        axios.get(this.props.configurationUrl + "/" + tenantId + "?slug=" + this.state.accountSlug + "&region=" + this.state.portalCode,
            {
                headers: {
                    'Authorization': 'Jwt ' + jwt.accessToken
                }
            }).then((taskResponse) => {

            var config = taskResponse.data;
            if (config && config.users && config.users.length === 0) {
                config = "";
            }
            this.props.onLogin(
                this.state.portalCode,
                this.state.accountSlug,
                this.state.authId,
                this.state.authToken,
                this.state.botA_id,
                this.state.botB_id,
                jwt,
                tenantId,
                domainName,
                config,
                this.state.accountType,
                this.state.chatRecordingAppId,
                this.state.adSyncAppId,
                this.state.convenienceBot
            );
            this.setState({ loading: false });
            if (config === "") {
                let history = useHistory();
                history.push("/newpolicy");
            }
        }).catch(error => {
            if (error.response && error.response.status === 400) {
                this.setState({
                    showAlertModal: true,
                    alertModalText: error.response.data,
                    loading: false,
                    accountSlug: originalSlug
                })
                return;
            }
            
            this.props.onLogin(
                this.state.portalCode,
                this.state.accountSlug,
                this.state.authId,
                this.state.authToken,
                this.state.botA_id,
                this.state.botB_id,
                this.state.accessToken,
                tenantId,
                domainName,
                "",
                this.state.convenienceBot
            );
        });

    }

    render() {
        return (
            <div id="main" className="ui text container transition">
                <div id="stepIntro" className={this.state.stepIntroVisible ? 'wizard-step' : 'hidden-wizard-step'}>
                    <Intro onIntroNextClicked={this.onIntroNextClicked} />
                </div>
                <div id="step1Header" className={this.state.step1visible ? 'wizard-step' : 'hidden-wizard-step'}>
                    <ConnectToDubber portalVerified={this.portalVerified} provisionUrl={this.props.provisionUrl} cancel={this.cancelStep1} />
                </div>
                <div id="step2Header" className={this.state.step2visible ? 'wizard-step' : 'hidden-wizard-step'}>
                    <ConnectToTeams tenantIdEntered={this.tenantIdEntered} configurationUrl={this.props.configurationUrl} cancel={this.cancelStep2} accountType={this.state.accountType} childAccounts={this.state.accounts} setAccountSlug={this.setAccountSlug} />
                </div>
                <div className="ui center aligned container head">
                    <div className={this.state.loading ? "spinner-div" : "spinner-hidden"}>
                        <div className="middle-of-page">
                            <Ring />
                        </div>
                    </div>
                </div>
                <AlertModal 
                    Title={"Error"}
                    Visible={this.state.showAlertModal}
                    BodyText={this.state.alertModalText}
                />
            </div>
        );
    }
}

export { Login }