import React from "react"
import logo from './DubberMSTeams.png';

class Intro extends React.Component {
    render() {
        return (
            <div className="ui center aligned container head">
                <img src={logo} alt="Dubber MS Teams"/>
                <div className="ui center aligned container intro">
                    <p>The Dubber MS teams web provisioning portal will assist you in setting up the MS Teams Compliance Recording Policy and 
                        associated users in Dubber. This is done by running Powershell scripts.<br /><br />
                        Where an account or user runs a hybrid (cloud + on-prem) setup, the provisioning script is supported as long as customers 
                        authenticate with the Azure Active Directory (specifically Office 365 / onmicrosoft account). Please note the management 
                        of the scripts and or provisioning implementation must be executed by someone with full administrative credentials. 
                    </p>
                </div>
                <div className="link">
                    <button className="next-link" onClick={this.props.onIntroNextClicked}>Get Started</button>
                </div>
                <div className="disclaimer">
                    <h3 className="disclaimer">Disclaimer</h3>
                    <p>This provisioning tool is intended to assist administrators with creating a recording policy in MS teams and provisioning users in Dubber. It is aimed to assist in setting up Dubber call recording where the MS teams set-up is standard. In the event that the MS teams implementation has been highly customised, there are no guarantees that this tool will be free of errors.</p>
                </div>
        </div>);
    }
}

export { Intro }