import React from 'react';
import logo from './DubberMSTeamsSmall.png';
import infoicon from './InfoIcon.png';
import copyIcon from './CopyIcon.png';

class ConnectToTeams extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tenantId: "",
            errorMessage: "",
            domainName: "",
            accountSlug: ""
        }
        this.checkAccount = this.checkAccount.bind(this);
        this.tenantIdChange = this.tenantIdChange.bind(this);
        this.domainNameChanged = this.domainNameChanged.bind(this);
        this.accountChanged = this.accountChanged.bind(this);
    }

    copyTextArea(event) {
        var copyText = document.getElementById("psPrerequisitsTextArea");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
    }

    copyConnectToTeamsTextArea(event) {
        var copyText = document.getElementById("connectToTeamsTextArea");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
    }

    copyConnectToAzureADTextArea(event) {
        var copyText = document.getElementById("connectToAzureADTextArea");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
    }

    checkAccount(event) {
        let errorMessages = [];

        if (this.state.domainName.includes("@")) {
            errorMessages.push("The '@' symbol is invalid in a domain name.");
        }
        
        if (this.state.tenantId === "" || this.state.domainName === "") {
            errorMessages.push("Enter the Tenant ID and Domain Name.");
        }

        if (this.props.accountType === "group" && this.state.accountSlug === "") {
            errorMessages.push("Select an account.");
        }
        
        if (errorMessages.length > 0){
            this.setState({ errorMessage: errorMessages.join(' ') });
            return;
        }

        this.setState({ errorMessage: "" });
        this.props.tenantIdEntered(this.state.tenantId, this.state.domainName, this.state.accountSlug);
    }

    tenantIdChange(event) {
        this.setState({ tenantId: event.target.value });
    }

    domainNameChanged(event) {
        this.setState({ domainName: event.target.value });
        if (event.target.value.includes("@")) {
            this.setState({ errorMessage: "The '@' symbol is invalid in a domain name." });
        } else {
            this.setState({ errorMessage: "" });
        }
    }

    accountChanged(event) {
        event.preventDefault();
        
        let accountSlug = this.props.childAccounts?.filter(account => account.name === event.target.value);
        
        if (accountSlug.length === 0){
            this.setState({ accountSlug: "" });
            return;
        }
        
        this.setState({ accountSlug: accountSlug[0].id });
    }

    render() {
        var psCommand = "Install-PackageProvider -Name NuGet -MinimumVersion 2.8.5.201 -Force -ErrorAction SilentlyContinue \n" +
            "Install-Module MicrosoftTeams -Force\n" +
            "Import-Module MicrosoftTeams\n" +
            "Update-Module MicrosoftTeams -Force -ErrorAction SilentlyContinue \n" +
            "Install-Module AzureAD -Force\n" +
            "Import-Module AzureAD\n";
        var psCommandConnectToTeams = "Connect-MicrosoftTeams";
        var psCommandConnectToAzureAD = "Connect-AzureAD";

        let selectAccounts = "";
        let selectAccountsInstructions = "";
        if (this.props.accountType === "group") {
            selectAccountsInstructions = <li>Select the child account for provisioning.</li>
            
            selectAccounts = <div className="fields noTopPadding">
                <label htmlFor="accountName">Select Account</label>
                <input id="accountName" list="accountList" placeholder="Select" onChange={this.accountChanged.bind(this)} />
                <datalist id="accountList">
                    {this.props.childAccounts?.map((account) =>
                        <option key={account.id} value={account.name} />
                    )}
                </datalist>
            </div>;
        }

        return (
            <div className="no-top-margin">
                <div className="ui center aligned container head">
                    <div className="stepheader">
                        <div className="step">Step 2</div>
                        <img src={logo} alt="Dubber MS Teams" className="small-logo" />
                        <div className="step-back"><button onClick={this.props.cancel}>&lt; Back</button></div>
                    </div>
                    <div className="ui aligned container start">
                        <div className="step-header">
                            <h3>Connect to Teams</h3>
                            <p>The provisioning of the Compliance Recorders for MS Teams can only be accomplished through PowerShell.  The PowerShell module 'MicrosoftTeams' contains the command required to create the policy.  The command 'Connect-MicrosoftTeams' creates a connection to the MS Teams server where the compliance recording policy it created.  The 'Connect-AzureAD' is required to retrieve the current list of users from Azure AD to be provisioned in the Dubber Portal.</p>
                        </div>
                        <div className="instructions">
                            <img src={infoicon} alt="i" />
                            <br />
                            <b>Prerequisites</b>
                            <ol>
                                <li>Ensure you have PowerShell version 5.1<br /><sub>PowerShell 5.1 is included in the Windows Management Framework 5.1</sub></li>
                                <li>Run PowerShell as Administrator<br /><sub>right click on the PowerShell icon and select run as administrator</sub></li>
                                <li>Once the PowerShell window has opened, use the commands provided to install the NuGet package provider module<br /><sub>You may be prompted to continue.</sub>
                                </li>
                                <li>Connect to Azure AD and enter your authentication details<br /><sub>&nbsp;</sub></li>
                                <li>Connect to Microsoft Teams and again enter your authentication details</li>
                            </ol>
                        </div>
                        <div className="column-half">
                            <div className="space80px">
                                Click to<a href="https://www.microsoft.com/en-us/download/details.aspx?id=54616" className="download" target="_blank" rel="noopener noreferrer">Download PowerShell</a>
                            </div>
                            <div className="space30px">
                                Right click on PowerShell and select "Run as Administrator"
                            </div>
                            <div className="space20px">
                                <textarea id="psPrerequisitsTextArea" className="connectToTeams" defaultValue={psCommand}>
                                </textarea>
                                <button className="copyText" onClick={this.copyTextArea} ><img className="copyIcon" src={copyIcon} alt="" />Copy</button>
                                <br />
                                <sub>To continue press 'Y'</sub>
                            </div>
                            <div className="space20px">
                                <textarea id="connectToAzureADTextArea" className="connectToTeams" defaultValue={psCommandConnectToAzureAD}>
                                </textarea>
                                <button className="copyText" onClick={this.copyConnectToAzureADTextArea} ><img className="copyIcon" src={copyIcon} alt="" />Copy</button>
                            </div>
                            <div className="space20px">
                                <textarea id="connectToTeamsTextArea" className="connectToTeams" defaultValue={psCommandConnectToTeams}>
                                </textarea>
                                <button className="copyText" onClick={this.copyConnectToTeamsTextArea} ><img className="copyIcon" src={copyIcon} alt="" />Copy</button>
                            </div>
                        </div>
                        <hr />
                        <div className="instructions">
                            <ol start="6">
                                <li>After running the Connect-MicrosoftTeams command, the Tenant ID will be listed in the output.  Copy the Tenant ID and the Tenant Domain Name into the field provided.</li>
                                <li>Enter your domain name associated to the Tenant ID</li>
                            </ol>
                            <sub><a className="download" href="https://docs.microsoft.com/en-us/azure/active-directory/fundamentals/active-directory-how-to-find-tenant" target="_blank" rel="noopener noreferrer">Need assistance finding the Tenant Id?</a></sub>
                            <ol start="8">
                                {selectAccountsInstructions}
                            </ol>
                        </div>
                        <div className="column-half">
                            <div className="fields">
                                <label htmlFor="tenantIdTextBox">Enter Tenant ID</label>
                                <input id="tenantIdTextBox" onChange={this.tenantIdChange} />
                                <label htmlFor="domainNameTextBox">Enter the associated Azure AD Domain Name</label>
                                <input id="domainNameTextBox" onChange={this.domainNameChanged} />
                                {selectAccounts}
                                <label id="errorMessageLabel" className="errorText">{this.state.errorMessage}</label>
                            </div>
                        </div>
                    </div>
                    <div className="link">
                        <button onClick={this.checkAccount} className="next-link" >Next</button>
                    </div>
                </div>
            </div>
        );
    }
}

export { ConnectToTeams }
