import React from 'react';

import { OverrideSetting } from './OverrideSetting';
import { OverrideCallClassification } from './OverrideCallClassification';

class UserSetting extends React.Component {
    constructor(props) {
        super(props);
        var tenantPermissionPauseResumeCall = this.getTenantSettingValue(props.tenantSettings, "permissionPauseResumeCall") === "true";
        var tenantPermissionDiscardCall = this.getTenantSettingValue(props.tenantSettings, "permissionDiscardCall") === "true";
        var tenantRecordVideo = this.getTenantSettingValue(props.tenantSettings, "recordVideo") === "true";
        var tenantrecordScreenShare = this.getVideoLegacySettingValue(props.tenantSettings, "recordScreenShare", tenantRecordVideo);
        var tenantOnDemandRecording = this.getTenantSettingValue(props.tenantSettings, "onDemandRecording");
        var tenantCallClassification = this.getTenantSettingValue(props.tenantSettings, "recordCallClassifications") ?? "";

        this.state = {
            editSettings: false,
            user: props.user,
            displayName: props.user.displayName,
            permissionPauseResumeCall: props.user.permissionPauseResumeCall,
            permissionDiscardCall: props.user.permissionDiscardCall,
            recordVideo: props.user.recordVideo,
            recordScreenShare: props.user.recordScreenShare,
            onDemandRecording: props.user.onDemandRecording,
            userSlug: props.user.userSlug,
            recordAllCalls: props.user.recordAllCalls,
            recordExternalPartyCalls: props.user.recordExternalPartyCalls,
            recordMeetings: props.user.recordMeetings,
            recordPSTN: props.user.recordPSTN,
            tenantSettings: props.tenantSettings,
            tenantPermissionPauseResumeCall: tenantPermissionPauseResumeCall,
            tenantPermissionDiscardCall: tenantPermissionDiscardCall,
            tenantRecordVideo: tenantRecordVideo,
            tenantrecordScreenShare: tenantrecordScreenShare,
            tenantOnDemandRecording: tenantOnDemandRecording === null ? 'true' : tenantOnDemandRecording === 'true',
            tenantCallClassificationRecordAllCalls: tenantCallClassification.includes("all"),
            tenantCallClassificationRecordExternalPartyCalls: tenantCallClassification.includes("externalParties"),
            tenantCallClassificationRecordMeetings: tenantCallClassification.includes("meetings"),
            tenantCallClassificationRecordPSTN: tenantCallClassification.includes("pstn"),
            editable: props.user.dubPointExternalGroup === props.tenantId && props.user.dubPointStatus !== "Suspended"
        };

        this.onPermissionPauseResumeCallChanged = this.onPermissionPauseResumeCallChanged.bind(this);
        this.onPermissionDiscardCallChanged = this.onPermissionDiscardCallChanged.bind(this);
        this.onRecordVideoChanged = this.onRecordVideoChanged.bind(this);
        this.onrecordScreenShareChanged = this.onrecordScreenShareChanged.bind(this);
        this.editSettingsChanged = this.editSettingsChanged.bind(this);
        this.ononDemandRecordingChanged = this.ononDemandRecordingChanged.bind(this);
        this.onOverrideCallClassificationChanged = this.onOverrideCallClassificationChanged.bind(this);
    }

    getTenantSettingValue(settings, settingName) {
        var result = null;
        if (settings !== null && settings !== undefined && settings.settings !== undefined && settings.settings !== null) {
            var setting = settings.settings.find(s => s.name === settingName);
            if (setting !== null && setting !== undefined) {
                result = setting.value;
            }
        }
        return result;
    }

    getVideoLegacySettingValue(settings, settingName, defaultValue) {
        if (settings !== null && settings !== undefined && settings.settings !== undefined && settings.settings !== null) {
            var setting = settings.settings.find(s => s.name === settingName);
            if (setting !== null && setting !== undefined) {
                return setting.value;
            }
        }

        // default to recordVideo value if doesn't exist
        return defaultValue;
    }

    onPermissionPauseResumeCallChanged(overrideDefault, checked) {
        this.setState({ permissionPauseResumeCall: checked });
        if (overrideDefault) {
            this.props.saveUserSettings(this.state.user.externalId,
                "permissionPauseResumeCall",
                checked ? "true" : "false");
        } else {
            this.props.saveUserSettings(this.state.user.externalId,
                "permissionPauseResumeCall",
                null);
        }
    }

    onPermissionDiscardCallChanged(overrideDefault, checked) {
        this.setState({ permissionDiscardCall: checked });
        if (overrideDefault) {
            this.props.saveUserSettings(this.state.user.externalId,
                "permissionDiscardCall",
                checked ? "true" : "false");
        } else {
            this.props.saveUserSettings(this.state.user.externalId,
                "permissionDiscardCall",
                null);
        }
    }

    onRecordVideoChanged(overrideDefault, checked) {
        this.setState({ recordVideo: checked });
        if (overrideDefault) {
            this.props.saveUserSettings(this.state.user.externalId,
                "recordVideo",
                checked ? "true" : "false");
        } else {
            this.props.saveUserSettings(this.state.user.externalId,
                "recordVideo",
                null);
        }
    }

    onrecordScreenShareChanged(overrideDefault, checked) {
        this.setState({ recordScreenShare: checked });
        if (overrideDefault) {
            this.props.saveUserSettings(this.state.user.externalId,
                "recordScreenShare",
                checked ? "true" : "false");
        } else {
            this.props.saveUserSettings(this.state.user.externalId,
                "recordScreenShare",
                null);
        }
    }

    editSettingsChanged(event) {
        var current = this.state.editSettings;
        event.preventDefault();
        this.setState({ editSettings: !current });
    }

    ononDemandRecordingChanged(overrideDefault, checked) {
        this.setState({ onDemandRecording: !checked });
        if (overrideDefault) {
            this.props.saveUserSettings(this.state.user.externalId, "onDemandRecording", !checked ? "true" : "false");
        } else {
            this.props.saveUserSettings(this.state.user.externalId, "onDemandRecording", null);
        }
    }

    onOverrideCallClassificationChanged(overrideDefault, recordAllCalls, recordExternalPartyCalls, recordMeetings, recordPSTN) {
        this.setState({
            recordAllCalls: recordAllCalls,
            recordExternalPartyCalls: recordExternalPartyCalls,
            recordMeetings: recordMeetings,
            recordPSTN: recordPSTN
        });

        if (overrideDefault) {
            let recordCallClassifications = "";
            if (recordAllCalls) {
                recordCallClassifications += "all,";
            }
            if (recordExternalPartyCalls) {
                recordCallClassifications += "externalParties,";
            }
            if (recordMeetings) {
                recordCallClassifications += "meetings,";
            }
            if (recordPSTN) {
                recordCallClassifications += "pstn,";
            }
            this.props.saveUserSettings(this.state.user.externalId,
                "recordCallClassifications",
                recordCallClassifications);
        } else {
            this.props.saveUserSettings(this.state.user.externalId,
                "recordCallClassifications",
                null);
        }
    }

    render() {
        var recordVideoBlock = <div></div>;
        if (this.props.showVideo) {
            recordVideoBlock = <div><OverrideSetting
                settingLabel="Record Video"
                defaultValue={this.state.recordVideo}
                parentValue={this.state.tenantRecordVideo}
                valueChanged={this.onRecordVideoChanged}>
            </OverrideSetting>
                <OverrideSetting
                    settingLabel="Record Screen Share"
                    defaultValue={this.state.recordScreenShare}
                    parentValue={this.state.tenantrecordScreenShare}
                    valueChanged={this.onrecordScreenShareChanged}>
                </OverrideSetting></div>;
        }
        let autoStartRecording = null;
        if (this.state.onDemandRecording != null) {
            autoStartRecording = !this.state.onDemandRecording;
        }
        return (
            <div className="divTableRow">
                <div className="divTableCell"><b>{this.state.displayName} {this.state.user.dubPointStatus === "Suspended" ? "(Dub Point Suspended)" : ""} {this.props.user.dubPointExternalGroup !== this.props.tenantId ? "(Different Tenant)" : ""}</b><br/><sub>{this.state.userSlug}</sub></div>
                <div className="divTableCell settings-column">
                    <button id="expandSettingsButton" className={!this.state.editable ? "hidden-expand" : "expandsettings"} disabled={!this.state.editable} onClick={this.editSettingsChanged}>{this.state.editSettings ? "hide" : "edit"}</button>
                    <div className={this.state.editSettings ? "showEditSettings" : "hideEditSettings"}>
                        <OverrideSetting settingLabel="Allow Pause Resume Calls"
                            settingLabelTitle="Give permission to users to pause and resume the calls. It is a separate feature that does not associate to other settings such as Call Classification, Auto start recording or Recording Video"   
                                         defaultValue={this.state.permissionPauseResumeCall}
                                         parentValue={this.state.tenantPermissionPauseResumeCall}
                                         valueChanged={this.onPermissionPauseResumeCallChanged} />
                        <br />
                        
                        {/*<OverrideSetting settingLabel="Allow Discard Call"*/}
                        {/*                 defaultValue={this.state.permissionDiscardCall}*/}
                        {/*                 parentValue={this.state.tenantPermissionDiscardCall}*/}
                        {/*                 valueChanged={this.onPermissionDiscardCallChanged} />*/}

                        {/*<br />*/}

                        <OverrideSetting settingLabel="Auto start Recording"
                                         defaultValue={autoStartRecording}
                                         parentValue={!this.state.tenantOnDemandRecording}
                                         valueChanged={this.ononDemandRecordingChanged} />
                        <OverrideCallClassification
                            valueChanged={this.onOverrideCallClassificationChanged}
                            recordAllCalls={this.state.recordAllCalls}
                            parentRecordAllCalls={this.state.tenantCallClassificationRecordAllCalls}
                            recordExternalPartyCalls={this.state.recordExternalPartyCalls}
                            parentRecordExternalPartyCalls={this.state.tenantCallClassificationRecordExternalPartyCalls}
                            recordMeetings={this.state.recordMeetings}
                            parentRecordMeetings={this.state.tenantCallClassificationRecordMeetings}
                            parentRecordPSTN={this.state.tenantCallClassificationRecordPSTN}
                            recordPSTN={this.state.recordPSTN} />

                        <br />
                        {recordVideoBlock}
                    </div>
                </div>
            </div>
        );
    }
}

export { UserSetting }