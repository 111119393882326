import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import logo from './DubberMSTeamsSmall.png';
import infoicon from './InfoIcon.png';
import cross from './RedCross.png'
import questionMark from './BlueQuestionMark.png'
import tick from './TransparentTick.png'
import axios from "axios";
import { Ring } from "react-awesome-spinners";

class ConsentPermissions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            consentAConfirmed: false,
            consentBConfirmed: false,
            convenienceConfirmed: false,
            showConvenienceLink: false,
            loading: false,
            errorMessage: "",
            consentChecked: false
    };

        this.confirmConsent = this.confirmConsent.bind(this);
    }

    confirmConsent(event) {
        this.setState({ loading: true });
        event.preventDefault();
        let config = {
            headers: { 'Authorization': 'Jwt ' + this.props.accessToken } 
        };
        let body = {
            portal: this.props.portal,
            slug: this.props.slug,
            tenantId: this.props.tenantId,
            type: 'recordingBot'
        };
        this.setState({
            errorMessage: ""
        });
        axios.post(this.props.confirmConsentUrl, body, config)
            .then(taskResponse => {
                this.setState({
                    loading: false,
                    consentAConfirmed: taskResponse.data.botAConsentConfirmed,
                    consentBConfirmed: taskResponse.data.botBConsentConfirmed,
                    convenienceConfirmed: taskResponse.data.convenienceBotConfirmed,
                    showConvenienceLink: taskResponse.data.convenienceProductFound,
                    consentChecked: true
                });
            }).catch(error => {
                this.setState({ loading: false });
                console.log(error.data);
                this.setState({ errorMessage: error.data, consentChecked: true });
            });
    }

    render() {
        var linkA = "https://login.microsoftonline.com/" + this.props.tenantId + "/adminconsent?client_id=" + this.props.botA;
        var linkB = "https://login.microsoftonline.com/" + this.props.tenantId + "/adminconsent?client_id=" + this.props.botB;
        var linkConvenience = "https://login.microsoftonline.com/" + this.props.tenantId + "/adminconsent?client_id=" + this.props.convenienceBot;
        var botATick = this.state.consentAConfirmed ? <img className="green-tick" src={tick} /> : <img className="green-tick" src={cross} />;
        var botBTick = this.state.consentBConfirmed ? <img className="green-tick" src={tick} /> : <img className="green-tick" src={cross} />;
        var botConvenienceTick = this.state.consentBConfirmed ? <img className="green-tick" src={tick} /> : <img className="green-tick" src={cross} />;
        if (!this.state.consentChecked) {
            botATick = <img className="green-tick" src={questionMark} />;
            botBTick = <img className="green-tick" src={questionMark} />;
        }
        if (!this.state.convenienceConfirmed) {
            botConvenienceTick = <img className="green-tick" src={questionMark} />;
        }

        var convenienceBotLink = "";
        if (this.state.showConvenienceLink) {
            convenienceBotLink = (
                <div className="space20px">
                    <h4>Step 3</h4>
                    <a href={linkConvenience} className="download" target="convenienceBot" rel="noopener noreferrer">Consent permissions for Convenience Bot</a>{botConvenienceTick}
                </div>
                );

        }

        return (
            <div>
                <div className="ui center aligned container head">
                    <div className="stepheader">
                        <div className="step">Step 5</div>
                        <img src={logo} alt="Dubber MS Teams" className="small-logo" />
                        <div className="step-back"><button onClick={this.props.cancel}>&lt; Back</button></div>
                    </div>
                    <div className="ui aligned container start">
                        <div className="step-header">
                            <h3>Consent Permissions for Recorder A and Recorder B</h3>
                        </div>
                        <div className="instructions">
                            <img src={infoicon} alt="i" />
                            <br />
                            <p><b>You must consent permissions for the Bots to record calls and meetings</b></p>
                            <p>
                                Dubber offers full redundancy of calls by recording calls twice, via two Dubber Microsoft Recorders.
                            </p>
                            <p>We require consent to the following permissions to enable the Bots to initiate recording:
                            </p>
                            <ul>
                                <li>Sign in and read user profile</li>
                                <li>Read all call records</li>
                                <li>Access media streams in a call as an app</li>
                                <li>Join group calls and meets as an app</li>
                            </ul>
                            <p>The links will open in a new tab and the credentials used to consent the permissions must have the role Global Admin</p>
                        </div>
                        <div className="column-half">
                            <div className="space20px">&nbsp;
                            </div>
                            <div className="space20px">
                                <h4>Step 1</h4>
                                <a href={linkA} className="download" target="botA" rel="noopener noreferrer">Consent permissions for recording Bot A</a>{botATick}
                                <h4>Step 2</h4>
                                <a href={linkB} className="download" target="botB" rel="noopener noreferrer">Consent permissions for recording Bot b</a>{botBTick}
                            </div>
                            {convenienceBotLink}
                        </div>
                        <label id="errorMessageLabel" className="errorText">{this.state.errorMessage}</label>
                    </div>
                    <div className={!this.state.consentAConfirmed || !this.state.consentBConfirmed || (this.state.showConvenienceLink && !this.state.convenienceConfirmed) ? "link" : "hidden-wizard-step"}>
                        <button onClick={this.confirmConsent} className="next-link" >Confirm</button>
                    </div>
                    <div className={this.props.config === "" && this.state.consentAConfirmed && this.state.consentBConfirmed && (!this.state.showConvenienceLink || this.state.showConvenienceLink && this.state.convenienceConfirmed) ? "link" : "hidden-wizard-step"}>
                        <div><NavLink tag={Link} to="/addusers">Next</NavLink></div>
                    </div>
                    <div className={this.props.config !== "" && this.state.consentAConfirmed && this.state.consentBConfirmed && (!this.state.showConvenienceLink || this.state.showConvenienceLink && this.state.convenienceConfirmed) ? "link" : "hidden-wizard-step"}>
                        <div><NavLink tag={Link} to="/">Next</NavLink></div>
                    </div>
                    <div className={this.state.loading ? "spinner-div" : "spinner-hidden"}>
                        <div className="middle-of-page">
                            <Ring />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export { ConsentPermissions }