import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import logo from './DubberMSTeamsSmall.png';
import infoicon from './InfoIcon.png';
import copyIcon from './CopyIcon.png';
import uploadIcon from './UploadIcon.png';
import axios from "axios";
import { Ring } from "react-awesome-spinners";

class DiscoverUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: "",
            selectedFile: null,
            filename: "",
            loading: false
        };
        this.uploadFile = this.uploadFile.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
    }

    uploadFile(event) {
        event.preventDefault();
        if (!this.state.selectedFile) {
            this.setState({ errorMessage: "Select a file before continuing" });
        } else {
            if (this.props.processUsersUrl) {
                this.setState({ loading: true });

                const formData = new FormData();
                formData.append("file", this.state.selectedFile, this.state.selectedFile.name);
                formData.append("portalCode", this.props.portalCode);
                formData.append("slug", this.props.slug);
                formData.append('accountType', this.props.accountType);
                axios.post(this.props.processUsersUrl,
                    formData,
                    { headers: { 'Authorization': 'Jwt ' + this.props.accessToken } }).then(taskResponse => {
                    this.setState({
                        loading: false,
                        errorMessage: ""
                    });
                    this.props.next(taskResponse.data.users, taskResponse.data.subscriptions, this.state.selectedFile);
                }).catch(error => {
                    this.setState({ loading: false });
                    console.log(error);
                    if (error.message === "Network Error") {
                        this.setState({
                            errorMessage: "File has changed.  Please click Upload and reselect file.",
                            selectedFile: null,
                            filename: ""
                        });
                    } else {
                        this.setState({
                            errorMessage: (error.response && error.response.data)
                                ? error.response.data
                                : "Unable to process file."
                        });
                    }
                });
            }
        }
    }

    copyTextArea(event) {
        var copyText = document.getElementById("discoverUsersTextArea");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
    }

    copyChangeDirectoryTextArea(event) {
        var copyText = document.getElementById("changeDirectoryTextArea");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
    }

    onFileChange(event) {
        event.preventDefault();

        let reader = new FileReader();
        let file = event.target.files[0];
        let filename = event.target.files[0].name;
        reader.onloadend = () => {
            this.setState({ selectedFile: file, filename: filename });
            document.getElementById("usersFileUpload").value = "";
        }
        reader.readAsDataURL(file);
    }

    render() {
        var psCommandDiscoverUsers =
            "Get-AzureAdUser -Top 1000 | SELECT ObjectId, UserPrincipalName, GivenName, Surname, Department | Export-Csv -Path .\\AllUsers.csv -NoTypeInformation";
        return (
            <div>
                <div className="ui center aligned container head">
                    <div className="stepheader">
                        <div className="step">Step 6</div>
                        <img src={logo} alt="Dubber MS Teams" className="small-logo" />
                        <div className="step-back"><NavLink tag={Link} to="/">&lt; Back</NavLink></div>
                    </div>
                    <div className="ui aligned container start">
                        <div className="step-header">
                            <h3>Discover Users</h3>
                            <p>In order to provision your Dubber account users and recording end points, a list of available Azure AD Users is required.  Running the second command will select all of the users from Azure AD and create the 'AllUsers.csv' file.  Upload this file in step 3 to continue.</p>
                        </div>
                        <div className="instructions">
                            <img src={infoicon} alt="i" />
                            <br />
                            <ol>
                                <li>Change directory to your home directory</li>
                                <sub>This will save the CSV file created below in your home directory</sub>
                            </ol>
                        </div>
                        <div className="column-half">
                            <div className="space20px">&nbsp;
                            </div>
                            <div className="space20px">
                                <textarea id="changeDirectoryTextArea" className="getAzUsers" defaultValue={"cd ~"} />
                                <button className="copyText" onClick={this.copyChangeDirectoryTextArea} ><img className="copyIcon" src={copyIcon} alt="" />Copy</button>
                            </div>
                        </div>
                        <div className="instructions">
                            <ol start="2">
                                <li>Get List of Current AD Users</li>
                            </ol>
                        </div>
                        <div className="column-half">
                            <div className="space20px">&nbsp;
                            </div>
                            <div className="space20px">
                                <textarea id="discoverUsersTextArea" className="getAzUsers" defaultValue={psCommandDiscoverUsers} />
                                <button className="copyText" onClick={this.copyTextArea} ><img className="copyIcon" src={copyIcon} alt="" />Copy</button>
                            </div>
                        </div>
                        <hr />
                        <div className="instructions">
                            <ol start="3">
                                <li>Upload AllUsers.csv file created above</li>
                            </ol>
                        </div>
                        <div className="column-half">
                            <div className="space20px">
                                <input id="filename" className="uploadFilename" defaultValue={this.state.filename} readOnly={true}/>
                                <label className="custom-file-upload">
                                    <input type="file" id="usersFileUpload" className="uploadAdUsers" onChange={this.onFileChange} />
                                    <img className="uploadIcon" src={uploadIcon} alt="" />
                                    Upload
                                </label>
                            </div>
                        </div>
                        <label id="errorMessageLabel" className="errorText">{this.state.errorMessage}</label>
                    </div>
                    <div className="link">
                        <button onClick={this.uploadFile} className="next-link" >Next</button>
                    </div>
                    <div className={this.state.loading ? "spinner-div" : "spinner-hidden"}>
                        <div className="middle-of-page">
                            <Ring />
                        </div>
                    </div>
                </div>
            </div>
            );
    }
}

export { DiscoverUsers }
