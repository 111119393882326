import React, { Component } from 'react';
import logo from './DubberMSTeamsSmall.png';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import infoicon from './InfoIcon.png';
import copyIcon from './CopyIcon.png';
import Switch from "react-switch"

class AddUsersToAzure extends Component {
    constructor(props) {
        super(props);
        this.state = {
            playAnnouncements: props.playAnnouncements,
            alwaysAllowCalls: props.alwaysAllowCalls,
            newUsers: props.newUsers,
            users: props.users
        };
        this.copyNewUsers = this.copyNewUsers.bind(this);
        this.copyAllUsers = this.copyAllUsers.bind(this);

        this.allowRecordingWhenRecorderUnavailableChanged =
            this.allowRecordingWhenRecorderUnavailableChanged.bind(this);
        this.playCallRecordingAnnouncementsChanged =
            this.playCallRecordingAnnouncementsChanged.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.playAnnouncements !== undefined) {
            this.setState(
                {
                    playAnnouncements: nextProps.playAnnouncements,
                    alwaysAllowCalls: nextProps.alwaysAllowCalls,
                    newUsers: nextProps.newUsers,
                    users: nextProps.users
                });
        }
    }

    copyNewUsers(event) {
        var copyText = document.getElementById("addNewUsersTextArea");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
    } 

    copyAllUsers(event) {
        var copyText = document.getElementById("addAllUsersTextArea");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
    } 

    allowRecordingWhenRecorderUnavailableChanged(checked) {
        this.setState({ alwaysAllowCalls: checked });
    }

    playCallRecordingAnnouncementsChanged(checked) {
        this.setState({ playAnnouncements: checked });
    }

    donothing(event) {

    }

    render() {

        var securityGroupName = "MS Teams Dubber Recording Group";
        if (!this.state.playAnnouncements) {
            securityGroupName = securityGroupName + " No Announcements";
        }
        var psCommandAddNewUsers = "";
        if (this.state.newUsers && this.state.newUsers.createdDubPoints && this.state.newUsers.createdDubPoints.length > 0) {
            psCommandAddNewUsers = "$groupId = Get-AzureADGroup -All $True | Where-Object {$_.DisplayName -eq \"" + securityGroupName + "\"} | Select -First 1\n";
            this.state.newUsers.createdDubPoints.forEach((user) => {
                psCommandAddNewUsers += "Add-AzureAdGroupMember -ObjectId $groupId.ObjectId -RefObjectId '" +
                    user.externalIdentifier +
                    "' -ErrorAction SilentlyContinue\n";
            });
        }

        var psCommandAddAllUsers = "";
        if (this.state.users) {
            psCommandAddAllUsers = "$groupId = Get-AzureADGroup -All $True | Where-Object {$_.DisplayName -eq \"" + securityGroupName + "\"} | Select -First 1\n";
            this.state.users.forEach((user) => {
                if (user.externalGroup !== null && user.externalGroup !== "") {
                    psCommandAddAllUsers += "Add-AzureAdGroupMember -ObjectId $groupId.ObjectId -RefObjectId '" +
                        user.externalIdentifier +
                        "' -ErrorAction SilentlyContinue\n";
                }
            });
        }

        if (!this.props.newUsers) return <div></div>;
        return (
            <div>
                <div className="ui center aligned container head">
                    <div className="stepheader">
                        <div className="step">Step 8</div>
                        <img src={logo} alt="Dubber MS Teams" className="small-logo" />
                        <div className="step-back"><button onClick={this.props.cancel}>&lt; Back</button></div>
                    </div>
                    <div className="ui aligned container start">
                        <div className="step-header">
                            <h3>Add users to Azure recording group</h3>
                            <p>The following PowerShell scripts will add the newly selected users or all provisioned users to your Dubber Compliance Recording Security Group</p>
                        </div>
                        <div className="policy-options">
                            <label htmlFor="allowRecordingWhenRecorderUnavailableSwitch">Allow calls when recorders unavailable.</label><br />
                            <sub>{this.state.alwaysAllowCalls ? "Calls may still be made if recorders are unavailable" : "Compliance Recorders are required for calls"}</sub>
                            <div className="policy-switch" >
                                <Switch id="allowRecordingWhenRecorderUnavailableSwitch"
                                    borderRadius={9}
                                    height={18}
                                    width={36}
                                    onChange={this.allowRecordingWhenRecorderUnavailableChanged}
                                    checked={this.state.alwaysAllowCalls} />
                            </div>
                        </div>
                        <div className="policy-options">
                            <label htmlFor="playCallRecordingAnnouncementsSwitch">Play call recording announcement and display recording banner.</label><br />
                            <sub>{this.state.playAnnouncements ? "Default Call Recording Announcement will be used" : "Depending on your region, you may be required to inform the users they are being recorded"}</sub>
                            <div className="policy-switch" >
                                <Switch id="playCallRecordingAnnouncementsSwitch"
                                    borderRadius={9}
                                    height={18}
                                    width={36}
                                    onChange={this.playCallRecordingAnnouncementsChanged}
                                    checked={this.state.playAnnouncements} />
                            </div>
                        </div>
                        <p>The policy options associated with this removal script are Recording Announcements <b>{this.state.playAnnouncements ? "" : "Not"}</b> Enabled and Always Allow Calls {this.state.alwaysAllowCalls ? "Enabled" : "Disabled"}</p>

                        <div className="instructions">
                            <img src={infoicon} alt="i" />
                            <br />
                            <p>
                                To add only new users run this PowerShell Script
                            </p>
                        </div>
                        <div className="column-half">
                            <div className="space20px">&nbsp;
                            </div>
                            <div className="space20px">
                                <textarea id="addNewUsersTextArea" className="getAzUsers" value={psCommandAddNewUsers} />
                                <button className="copyText" onClick={this.copyNewUsers} ><img className="copyIcon" src={copyIcon} alt="" />Copy</button>
                            </div>
                        </div>
                        <div className="instructions">
                            <br />
                            <p>
                                To add all users run this PowerShell Script
                            </p>
                            <sub>Users already added will result in the error message: One or more added object references already exist for the following modified properties: 'members'.</sub>
                        </div>
                        <div className="column-half">
                            <div className="space20px">&nbsp;
                            </div>
                            <div className="space20px">
                                <textarea id="addAllUsersTextArea" className="getAzUsers" value={psCommandAddAllUsers} />
                                <button className="copyText" onClick={this.copyAllUsers} ><img className="copyIcon" src={copyIcon} alt="" />Copy</button>
                            </div>
                        </div>
                    </div>
                    <div className="link">
                        <div><NavLink tag={Link} to="/finish">Finish</NavLink></div>
                    </div>
                </div>
            </div>
        );
    }
}

export { AddUsersToAzure}