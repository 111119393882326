import React from 'react';

import Switch from "react-switch";

class OverrideCallClassification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            overrideAccountDefaults: this.props.recordAllCalls !== null,
            settingValueAll: this.props.recordAllCalls ?? this.props.parentRecordAllCalls,
            settingValuePSTN: this.props.recordPSTN ?? this.props.parentRecordPSTN,
            settingValueMeetings: this.props.recordMeetings ?? this.props.parentRecordMeetings,
            settingValueExternalParties: this.props.recordExternalPartyCalls ?? this.props.parentRecordExternalPartyCalls
        };

        this.overrideAccountDefaultChanged = this.overrideAccountDefaultChanged.bind(this);
        this.onSettingSwitchAllChanged = this.onSettingSwitchAllChanged.bind(this);
        this.onSettingSwitchExternalPartiesChanged = this.onSettingSwitchExternalPartiesChanged.bind(this);
        this.onSettingSwitchRecordMeetingsChanged = this.onSettingSwitchRecordMeetingsChanged.bind(this);
        this.onSettingSwitchRecordPSTNChanged = this.onSettingSwitchRecordPSTNChanged.bind(this);
    }

    overrideAccountDefaultChanged(event) {
        this.setState({ overrideAccountDefaults: event.target.checked });
        this.props.valueChanged(event.target.checked,
            this.state.settingValueAll,
            this.state.settingValueExternalParties,
            this.state.settingValueMeetings,
            this.state.settingValuePSTN);
    }

    onSettingSwitchAllChanged(checked) {
        if (checked) {
            this.setState({
                settingValueExternalParties: false,
                settingValueMeetings: false,
                settingValuePSTN: false
            });
        }
        this.setState({ settingValueAll: checked });
        this.props.valueChanged(this.state.overrideAccountDefaults,
            checked,
            this.state.settingValueExternalParties || checked,
            this.state.settingValueMeetings || checked,
            this.state.settingValuePSTN || checked);
    }

    onSettingSwitchExternalPartiesChanged(checked) {
        if (!checked) {
            this.setState({ settingValueAll: false });
        }
        this.setState({ settingValueExternalParties: checked });
        this.props.valueChanged(this.state.overrideAccountDefaults,
            this.state.settingValueAll && checked,
            checked,
            this.state.settingValueMeetings,
            this.state.settingValuePSTN);
    }

    onSettingSwitchRecordMeetingsChanged(checked) {
        if (!checked) {
            this.setState({ settingValueAll: false });
        } else {
            this.setState({ settingValueExternalParties: true, settingValuePSTN: true });
        }
        this.setState({ settingValueMeetings: checked });
        this.props.valueChanged(this.state.overrideAccountDefaults,
            this.state.settingValueAll && checked,
            this.state.settingValueExternalParties,
            checked,
            this.state.settingValuePSTN);
    }

    onSettingSwitchRecordPSTNChanged(checked) {
        if (!checked) {
            this.setState({ settingValueAll: false });
        } else {
            this.setState({ settingValueExternalParties: true, settingValueMeetings: true });
        }
        this.setState({ settingValuePSTN: checked });
        this.props.valueChanged(this.state.overrideAccountDefaults,
            this.state.settingValueAll && checked,
            this.state.settingValueExternalParties,
            this.state.settingValueMeetings,
            checked);
    }

    render() {
        return <div className="setting">
            <div style={{ minWidth: "153.12px" }}>Override Account Default</div>
            <div className="settingCheckbox" >
                <input
                    type="checkbox"
                    onChange={this.overrideAccountDefaultChanged}
                    defaultChecked={this.state.overrideAccountDefaults}
                    id="useAccountDefaultsPauseResume"/>
            </div>
            <div className="vertical-settings">
            <div className="switch-label">
                    <label title="Record calls that include people from outside, external parties and inside of your organisation." htmlFor="settingSwitchAll">Record All Calls</label>
            </div>

            <Switch id="settingSwitchAll"
                borderRadius={9}
                height={18}
                width={36}
                disabled={!this.state.overrideAccountDefaults}
                    onChange={this.onSettingSwitchAllChanged}
                    checked={this.state.overrideAccountDefaults ? this.state.settingValueAll : this.props.parentRecordAllCalls} />
            <br />
                <div className="switch-label" style={{ marginLeft: "15px" }}>----- OR -----</div>
            <br />
            <div className="switch-label">
                    <label title="Record calls that include people from outside your organisation. If compliance rules for your organisation make it compulsory to record calls with external parties, select this option or 'Record All Calls'." htmlFor="settingSwitchExternalParties">Record External Calls</label>
            </div>

            <Switch id="settingSwitchExternalParties"
                    borderRadius={9}
                    height={18}
                    width={36}
                    disabled={!this.state.overrideAccountDefaults}
                    onChange={this.onSettingSwitchExternalPartiesChanged}
                    checked={this.state.overrideAccountDefaults ? this.state.settingValueExternalParties : this.props.parentRecordExternalPartyCalls} />
            <div className="switch-label">
                    <label title="Calls that have more than two participants are classified as meetings." htmlFor="settingSwitchRecordMeetings">Record Meetings</label>
            </div>
            <br />

            <Switch id="settingSwitchRecordMeetings"
                borderRadius={9}
                height={18}
                width={36}
                disabled={!this.state.overrideAccountDefaults}
                onChange={this.onSettingSwitchRecordMeetingsChanged}
                    checked={this.state.overrideAccountDefaults ? this.state.settingValueMeetings : this.props.parentRecordMeetings} />

            <div className="switch-label">
                    <label title="PSTN calls include calls from landlines and cellular networks" htmlFor="settingSwitchRecordPstn">Record PSTN</label>
            </div>
            <br />

            <Switch id="settingSwitchRecordPstn"
                    borderRadius={9}
                    height={18}
                    width={36}
                    disabled={!this.state.overrideAccountDefaults}
                    onChange={this.onSettingSwitchRecordPSTNChanged}
                    checked={this.state.overrideAccountDefaults ? this.state.settingValuePSTN : this.props.parentRecordPSTN} />
            </div>
            <br />
        </div >;
    }
}

export { OverrideCallClassification }
