import React, { Component } from 'react';
import logo from './DubberMSTeamsSmall.png';
import infoicon from './InfoIcon.png';
import Switch from "react-switch";
import { PolicyUser } from './PolicyUser';
import axios from "axios";
import { Ring } from "react-awesome-spinners";

class SelectUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectAll: false,
            usersToProvision: [],
            errorMessage: "",
            users: props.users,
            loading: false,
            addPortalAccess: true,
            subscriptions: props.subscriptions,
            groupProduct: ""
        };

        this.getAvailableLicenses = this.getAvailableLicenses.bind(this);
        this.getUnlicensedUsers = this.getUnlicensedUsers.bind(this);
        this.getTotalLicenses = this.getTotalLicenses.bind(this);
        this.addUserChecked = this.addUserChecked.bind(this);
        this.userProductChanged = this.userProductChanged.bind(this);
        this.provisionUsers = this.provisionUsers.bind(this);
        this.addPortalAccessOnChange = this.addPortalAccessOnChange.bind(this);
        this.selectAllUsersChecked = this.selectAllUsersChecked.bind(this);
        this.groupProductChanged = this.groupProductChanged.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (typeof nextProps.users != "undefined") {
            this.setState({ users: nextProps.users });
        }
        if (typeof nextProps.subscriptions != "undefined") {
            this.setState({ subscriptions: nextProps.subscriptions });
        }
        if (typeof nextProps.products != "undefined") {
            this.setState({ products: nextProps.products });
        }
    }

    // AI Products DAI, UCRP
    productName(product) {
        var result = "Call Dub";
        if (product.startsWith("DAI")) {
            result = "Dub AI";
        } else if (product.startsWith("DGO-I")) {
            result = "DubberGo SP Edition";
        } else if (product.startsWith("DGOUC-I")) {
            result = "DubberGo UC Edition";
        } else if (product.startsWith("DGOSP-T")) {
            result = "Dubber CR SP Edition";
        } else if (product.startsWith("DR_T")) {
            result = "Dubber RECORDING";
        } else if (product.startsWith("DUR_T")) {
            result = "Dubber UNIFIED CAPTURE";
        } else if (product.startsWith("DURI_T")) {
            result = "Dubber INSIGHTS";
        } else if (product.startsWith("UCRY-I")) {
            result = "Dubber You";
        } else if (product.startsWith("UCRT-T")) {
            result = "Dubber Teams";
        } else if (product.startsWith("UCRP-T")) {
            result = "Dubber Premier";
        } else if (product.startsWith("UCRC-C")) {
            result = "Dubber Compliance Edition";
        } else if (product.startsWith("UCRP-C")) {
            result = "Dubber Premier Compliance Edition";
        } else if (product.startsWith("NBDMOM-T")) {
            result = "Notes by Dubber - Moments";
        } else if (product.startsWith("NBDQN-T")) {
            result = "Notes by Dubber - Quick Notes";
        } else if (product.startsWith("NBDPRO-T")) {
            result = "Notes by Dubber - Pro";
        }

        return result;
    }

    productCode(name) {
        var result = name;
        if (name.startsWith("Call")) {
            result = "CDU-T-01";
        } else if (name.startsWith("Dub AI")) {
            result = "DAI-T-01";
        } else if (name.startsWith("DubberGo SP Edition")) {
            result = "DGO-I-01";
        } else if (name.startsWith("DubberGo UC Edition")) {
            result = "DGOUC-I-01";
        } else if (name.startsWith("Dubber CR SP Edition")) {
            result = "DGOSP-T-01";
        } else if (name.startsWith("Dubber RECORDING")) {
            result = "DR_T_01";
        } else if (name.startsWith("Dubber UNIFIED CAPTURE")) {
            result = "DUR_T_01";
        } else if (name.startsWith("Dubber INSIGHTS")) {
            result = "DURI_T_01";
        } else if (name.startsWith("Dubber You")) {
            result = "UCRY-I-01";
        } else if (name.startsWith("Dubber Teams")) {
            result = "UCRT-T-01";
        } else if (name.startsWith("Dubber Premier")) {
            result = "UCRP-T-01";
        } else if (name.startsWith("Dubber Compliance Edition")) {
            result = "UCRC-C-01";
        } else if (name.startsWith("Dubber Premier Compliance Edition")) {
            result = "UCRP-C-01";
        } else if (name.startsWith("Notes by Dubber - Quick Notes")) {
            result = "NBDQN-T-01";
        } else if (name.startsWith("Notes by Dubber - Moments")) {
            result = "NBDMOM-T-01";
        } else if (name.startsWith("Notes by Dubber - Pro")) {
            result = "NBDPRO-T-01";
        }

        return result;
    }

    getUnlicensedUsers() {
        var result = 0;
        if (this.state.users) {
            for (var i = 0; i < this.state.users.length; i++) {
                var user = this.state.users[i];
                if (!user.product) {
                    result++;
                }
            }
        }
        return result;
    }

    getAvailableLicenses(product) {
        var result = 0;
        if (this.state.users) {
            for (var i = 0; i < this.state.users.length; i++) {
                var user = this.state.users[i];
                if (user.product && user.product.startsWith(product)) {
                    result ++;
                }
            }
        }

        return result;
    }

    getTotalLicenses(product) {
        var result = 0;
        if (this.state.subscriptions) {
            for (var i = 0; i < this.state.subscriptions.length; i++) {
                var sub = this.state.subscriptions[i];
                if (sub.product && sub.product.startsWith(product)) {
                    result += parseInt(sub.value);
                }
            }
        }
        return result;
    }

    addUserChecked(user, checked, productCode) {
        var usersToProvision = this.state.usersToProvision;
        if (checked) {
            user.externalGroup = this.props.tenantId;
            if (this.props.accountType === "group") {
                user.product = this.state.groupProduct;
            } else {
                user.product = productCode;
            }
            usersToProvision.push({ user: user, product: productCode });
            this.setState({ usersToProvision: usersToProvision });
        } else {
            var newUsersToProvision = [];
            user.externalGroup = null;
            user.product = "";
            usersToProvision.forEach( (entry) => {if (entry.user.externalIdentifier !== user.externalIdentifier) {
                newUsersToProvision.push(entry);
            }});
            this.setState({ usersToProvision: newUsersToProvision, selectAll: false });
        }
    }

    userProductChanged(externalIdentifier, product) {
        var usersToProvision = this.state.usersToProvision;
        usersToProvision.forEach((entry) => {
            if (entry.user.externalIdentifier === externalIdentifier) {
                entry.product = product;
            }
        });
        var allUsers = this.state.users;
        allUsers.forEach((entry) => {
            if (entry.externalIdentifier === externalIdentifier) {
                entry.product = product;
            }
        });

        this.setState({ users: allUsers, usersToProvision: usersToProvision });
    }

    provisionUsers(event) {
        var usersToProvision = this.state.usersToProvision;
        if (usersToProvision.length > 0) {
            this.setState({ loading: true });
            var postData = {
                users: usersToProvision,
                portalCode: this.props.portalCode,
                slug: this.props.slug,
                addPortalAccess: this.state.addPortalAccess
            };

            axios.post(this.props.provisionUsersUrl, postData, { headers: {
                    'Authorization': 'Jwt ' + this.props.accessToken
                }
            }).then(taskResponse => {
                this.props.next(taskResponse.data);
                this.setState({
                    loading: false,
                    usersToProvision: [],
                    errorMessage: ""
                });
            }).catch(error => {
                console.log(error);

                this.setState({ errorMessage: (error.response && error.response.data) ? error.response.data : "Unable to process file." });
                this.setState({ loading: false });
            });

        } else {
            this.props.next([]);
        }
    }

    addPortalAccessOnChange(checked) {
        this.setState({ addPortalAccess: checked });
    }

    groupProductChanged(event) {
        event.preventDefault();
        let usersToProvision = this.state.usersToProvision;
        let groupProductCode = this.productCode(event.target.value);
        this.setState({
            groupProductName: event.target.value,
            groupProduct: groupProductCode
        });
        if (groupProductCode !== "") {
            usersToProvision.forEach((user) => {
                if (!user.provisionForRecording && user.product !== "") {
                    user.product = groupProductCode;
                }
            });
        }
        this.setState({ usersToProvision: usersToProvision });
    }

    selectAllUsersChecked(checked) {
        var users = this.state.users;
        var usersToProvision = this.state.usersToProvision;

        if (checked) {
            if (this.props.accountType === "group") {
                if (this.state.groupProduct === "") {
                    this.setState({ errorMessage: "Select a Product." });
                    checked = false;
                } else {
                    users.forEach((user) => {
                        if (!user.provisionForRecording) {
                            user.product = this.state.groupProduct;
                            user.externalGroup = this.props.tenantId;
                            usersToProvision.push({ user: user, product: this.state.groupProduct });
                        }
                    });
                }
            } else {
                var productCounts = [];
                this.props.products().forEach((product) => {
                    var availableLicenses = this.getTotalLicenses(product) - this.getAvailableLicenses(product);
                    productCounts.push({ product: product, count: availableLicenses });
                });

                var userIndex = 0;
                productCounts.forEach((entry) => {
                    for (var i = 0; i < entry.count; i++) {
                        if (userIndex >= users.length) break;

                        var user = users[userIndex];
                        while (user.externalGroup !== null) {
                            userIndex++;
                            if (userIndex >= users.length) break;
                            user = users[userIndex];
                        }
                        if (user.externalGroup === null) {
                            user.product = entry.product;
                            user.externalGroup = this.props.tenantId;
                            usersToProvision.push({ user: user, product: entry.product });
                        }
                        userIndex++;
                    }
                });
            }
        } else {
            users.forEach((user) => {
                if (!user.provisionForRecording) {
                    user.product = "";
                    user.externalGroup = null;
                }
            });
            usersToProvision = [];
        }
        this.setState({selectAll: checked, users: users, usersToProvision: usersToProvision });
    }

    render() {
        var users = this.state.users.map((user) => {
            return (
                <PolicyUser key={user.externalIdentifier}
                    user={user}
                    onValueChanged={this.userProductChanged}
                    products={this.state.products}
                    product={user.product}
                    addUserChecked={this.addUserChecked}
                    availableLicenses={this.getAvailableLicenses}
                    totalLicenses={this.getTotalLicenses}
                    productName={this.productName}
                    selectAll={this.state.selectAll}
                    accountType={this.props.accountType}
                    groupProduct={this.state.groupProduct}
            />
            );
        });
        var productsBlock = <div></div>;
        if (this.props.products()) {
            var products = this.props.products().map((product) => {
                return <div key={product}>{this.productName(product)} Licenses: {this.getAvailableLicenses(product)}/{this.getTotalLicenses(product)}</div>;
            });
            productsBlock = <div className="space20px">&nbsp;
                <h4>Available Licenses: </h4>
                {products}
            </div>;
        }
        
        var groupHideClass = "";
        if (this.props.accountType === "group") {
            groupHideClass = "invisibleElement";
            productsBlock = <div className="space20px fields">&nbsp;
                <h4>Select Product</h4>
                <input id="groupProductInput" list="groupProducts" onChange={this.groupProductChanged
                } placeholder="Select Product" />
                <datalist id="groupProducts" value={this.state.groupProductName}>
                    {/*<option value="Call Dub" key="CTD-T-01"></option>*/}
                    {/*<option value="Dub AI" key="DAI-T-01"></option>*/}
                    {/*<option value="DubberGo SP Edition" key="DGO-I-01"></option>*/}
                    {/*<option value="DubberGo UC Edition" key="DGOUC-I-01"></option>*/}
                    {/*<option value="Dubber CR SP Edition" key="DGOSP-T-01"></option>*/}
                    <option value="Dubber You" key="UCRY-I-01"></option>
                    <option value="Dubber Teams" key="UCRT-T-01"></option>
                    <option value="Dubber Premier" key="UCRP-T-01"></option>
                {/*    <option value="Dubber Compliance Edition" key="UCRC-C-01"></option>*/}
                {/*    <option value="Dubber Premier Compliance Edition" key="UCRP-C-01"></option>*/}
                </datalist>
            </div>;
        }
        return (
            <div>
                <div className="ui center aligned container head">
                    <div className="stepheader">
                        <div className="step">Step 7</div>
                        <img src={logo} alt="Dubber MS Teams" className="small-logo" />
                        <div className="step-back"><button onClick={this.props.cancel}>&lt; Back</button></div>
                    </div>
                    <div className="ui aligned container start">
                        <div className="step-header">
                            <h3>Select Users</h3>
                        </div>
                        <div className="instructions">
                            <img src={infoicon} alt="i" />
                            <br />
                            <p>
                                Select the users below who should be recorded.  Users already provisioned in Dubber with an MS Teams Compliance Recording Policy license cannot be removed.
                            </p>
                            <p>
                                If you need to remove a dub point, log on to the Dubber portal and delete the dub point associated with the user.
                            </p>
                        </div>
                        <div className="column-half">
                            {productsBlock}
                            <div className="space20px">
                                <h4>New Users: </h4>
                                <label htmlFor="addPortalAccessSwitch">Create new users with Dubber Portal access.</label><br/>
                                <sub>{this.state.addPortalAccess ? "New users will be sent an email to setup their account" : "New users will not be able to login to the Dubber Portal"}</sub>
                                <div className="addProtalAccess" >
                                    <Switch id="addPortalAccessSwitch"
                                        borderRadius={9}
                                        height={18}
                                        width={36}
                                        onChange={this.addPortalAccessOnChange}
                                        checked={this.state.addPortalAccess} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ui aligned container start">
                        <div className="divTable">
                            <div className="divTableRow">
                                <div className="divTableHead column1">
                                    Add license<br />
                                    <div className="select-all-switch">
                                        <Switch id="selectAllSwitch"
                                            onChange={this.selectAllUsersChecked}
                                            checked={this.state.selectAll}
                                            borderRadius={9}
                                            height={18}
                                            width={36} />
                                        <label htmlFor="selectAllSwitch">All</label>
                                    </div>
                                </div>
                                <div className="divTableHead column2">User Name</div>
                                <div className="divTableHead column3"><span className={groupHideClass}>Product</span></div>
                                <div className="divTableHead centered column4">Provisioned In Dubber</div>
                            </div>
                        </div>
                        <div className="settingstable">
                            <div className="divTable">
                                <div className="divTableRow">
                                    <div className="divTableCell column1"></div>
                                    <div className="divTableCell column2"></div>
                                    <div className="divTableCell column3"></div>
                                    <div className="divTableCell column4"></div>
                                </div>
                                {users}
                            </div>
                        </div>
                        <label id="errorMessageLabel" className="errorText">{this.state.errorMessage}</label>
                    </div>
                    <div className="link">
                        <button onClick={this.provisionUsers} className="next-link" >Next</button>
                    </div>
                    <div className={this.state.loading ? "spinner-div" : "spinner-hidden"}>
                        <div className="middle-of-page">
                            <Ring />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export { SelectUsers }