import React from 'react';
import { Alert, Button, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import logo from './DubberMSTeamsSmall.png';
import infoicon from './InfoIcon.png';
import axios from "axios";
import { Ring } from "react-awesome-spinners";
import { UserSetting } from "./UserSetting";
import Switch from "react-switch";
import { ConfirmModal } from "./ConfirmModal";
import { AlertModal } from "./AlertModal";

class TenantSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            config: null,
            permissionPauseResumeCall: false,
            permissionDiscardCall: false,
            onDemandRecording: false,
            showVideo: false,
            recordVideo: false,
            recordScreenShare: false,
            recordAllCalls: false,
            recordExternalParties: false,
            recordMeetings: false,
            recordPSTN: false,
            confirmModalVisible: false,
            alertModalTitle: "",
            alertModalText: "",
            alertModalVisible: false,
        };
        this.saveTenantSettings = this.saveTenantSettings.bind(this);
        this.onPermissionPauseResumeCallChanged = this.onPermissionPauseResumeCallChanged.bind(this);
        this.onPermissionDiscardCallChanged = this.onPermissionDiscardCallChanged.bind(this);
        this.onOnDemandRecordingChanged = this.onOnDemandRecordingChanged.bind(this);
        this.onRecordAllCallsChanged = this.onRecordAllCallsChanged.bind(this);
        this.onRecordExternalPartiesChanged = this.onRecordExternalPartiesChanged.bind(this);
        this.onRecordVideoChanged = this.onRecordVideoChanged.bind(this);
        this.onrecordScreenShareChanged = this.onrecordScreenShareChanged.bind(this);
        this.onRecordMeetingsChanged = this.onRecordMeetingsChanged.bind(this);
        this.onRecordPSTNChanged = this.onRecordPSTNChanged.bind(this);
        this.onRecordCallClassificationChanged = this.onRecordCallClassificationChanged.bind(this);
        this.closeConfirmModal = this.closeConfirmModal.bind(this);
        this.deleteTenantSettings = this.deleteTenantSettings.bind(this);
        this.addTenantSettings = this.addTenantSettings.bind(this);
        this.openConfirmModal = this.openConfirmModal.bind(this);
        this.showAlertModal = this.showAlertModal.bind(this);
        this.closeAlertModal = this.closeAlertModal.bind(this);
        this.loadSettings = this.loadSettings.bind(this);
    }

    componentDidMount() {
        this.loadSettings();
    }

    loadSettings() {
        this.setState({
            loading: true
        });
        axios.get(this.props.configurationUrl + "/" + this.props.tenantId + "?slug=" + this.props.slug + "&region=" + this.props.portalCode,
            {
                headers: {
                    'Authorization': 'Jwt ' + this.props.accessToken
                }
            }).then((taskResponse) => {
                var permissionPauseResumeCall = this.getTenantSettingValue(taskResponse.data.settings, "permissionPauseResumeCall");
                var permissionDiscardCall = this.getTenantSettingValue(taskResponse.data.settings, "permissionDiscardCall");
                var recordVideo = this.getTenantSettingValue(taskResponse.data.settings, "recordVideo");
                var recordScreenShare = this.getVideoLegacySettingValue(taskResponse.data.settings, "recordScreenShare", recordVideo);
                var onDemandRecording = this.getTenantSettingValue(taskResponse.data.settings, "onDemandRecording");
                var accountSlug = this.getTenantSettingValue(taskResponse.data.settings, "dubberAccountSlug");
                var showVideoOption = taskResponse.data.videoEnabled;

                let recordAllCalls = true;
                let recordExternalParties = true;
                let recordMeetings = true;
                let recordPSTN = true;
                var recordCallClassifications = this.getTenantSettingValue(taskResponse.data.settings, "recordCallClassifications");
                if (recordCallClassifications != null) {
                    recordAllCalls = recordCallClassifications.includes("all");
                    recordExternalParties = recordCallClassifications.includes("externalParties");
                    recordMeetings = recordCallClassifications.includes("meetings");
                    recordPSTN = recordCallClassifications.includes("pstn");
                }

                this.setState({
                    permissionPauseResumeCall: permissionPauseResumeCall === "true",
                    permissionDiscardCall: permissionDiscardCall === "true",
                    accountSlug: accountSlug.value,
                    showVideo: showVideoOption,
                    loading: false,
                    recordVideo: recordVideo === "true",
                    recordScreenShare: recordScreenShare === "true",
                    onDemandRecording: onDemandRecording === "true",
                    recordAllCalls: recordAllCalls,
                    recordExternalParties: recordExternalParties,
                    recordMeetings: recordMeetings,
                    recordPSTN: recordPSTN,
                    config: taskResponse.data.settings
                });
            }).catch(error => {
                console.log(error);
                this.setState({ config: [], loading: false, errorMessage: error.data });
            });
    }

    getTenantSettingValue(settings, settingName) {
        if (settings !== null) {
            var setting = settings.find(s => s.name === settingName);
            if (setting !== null && setting !== undefined) {
                return setting.value;
            }
        }
        return "false";
    }

    getVideoLegacySettingValue(settings, settingName, defaultValue) {
        if (settings !== null) {
            var setting = settings.find(s => s.name === settingName);
            if (setting !== null && setting !== undefined) {
                return setting.value;
            }
        }

        // default to recordVideo value if doesn't exist
        return defaultValue;
    }

    onPermissionPauseResumeCallChanged(checked) {
        this.setState({ permissionPauseResumeCall: checked });
        this.saveTenantSettings("permissionPauseResumeCall", checked ? "true" : "false");
    }

    onPermissionDiscardCallChanged(checked) {
        this.setState({ permissionDiscardCall: checked });
        this.saveTenantSettings("permissionDiscardCall", checked ? "true" : "false");
    }

    onOnDemandRecordingChanged(checked) {
        this.setState({ onDemandRecording: !checked });
        this.saveTenantSettings("onDemandRecording", !checked ? "true" : "false");
    }

    onRecordVideoChanged(checked) {
        this.setState({ recordVideo: checked });
        this.saveTenantSettings("recordVideo", checked ? "true" : "false");
    }

    onrecordScreenShareChanged(checked) {
        this.setState({ recordScreenShare: checked });
        this.saveTenantSettings("recordScreenShare", checked ? "true" : "false");
    }

    onRecordCallClassificationChanged(recordAllCalls, recordExternalParties, recordMeetings, recordPSTN) {
        let recordCallClassifications = "";
        if (recordAllCalls) {
            recordCallClassifications += "all,";
        }
        if (recordExternalParties) {
            recordCallClassifications += "externalParties,";
        }
        if (recordMeetings) {
            recordCallClassifications += "meetings,";
        }
        if (recordPSTN) {
            recordCallClassifications += "pstn,";
        }
        this.saveTenantSettings("recordCallClassifications", recordCallClassifications);
    }

    onRecordAllCallsChanged(checked) {
        if (checked) {
            this.setState({
                recordExternalParties: false,
                recordMeetings: false,
                recordPSTN: false
            });
        }
        this.setState({ recordAllCalls: checked });
        this.onRecordCallClassificationChanged(checked,
            this.state.recordExternalParties || checked,
            this.state.recordMeetings || checked,
            this.state.recordPSTN || checked);
    }

    onRecordExternalPartiesChanged(checked) {
        if (!checked) {
            this.setState({ recordAllCalls: false });
        }
        this.setState({ recordExternalParties: checked });
        this.onRecordCallClassificationChanged(this.state.recordAllCalls && checked,
            checked,
            this.state.recordMeetings,
            this.state.recordPSTN);
    }

    onRecordMeetingsChanged(checked) {
        if (!checked) {
            this.setState({ recordAllCalls: false });
        } else {
            this.setState({ recordExternalParties: true, recordPSTN: true });
        }
        this.setState({ recordMeetings: checked });
        this.onRecordCallClassificationChanged(this.state.recordAllCalls && checked,
            this.state.recordExternalParties,
            checked,
            this.state.recordPSTN);
    }

    onRecordPSTNChanged(checked) {
        if (!checked) {
            this.setState({ recordAllCalls: false });
        } else {
            this.setState({ recordExternalParties: true, recordMeetings: true });
        }
        this.setState({ recordPSTN: checked });
        this.onRecordCallClassificationChanged(this.state.recordAllCalls && checked,
            this.state.recordExternalParties,
            this.state.recordMeetings,
            checked);
    }

    saveTenantSettings(settingName, settingValue) {
        this.setState({ loading: true });
        let body = {
            settingValue: settingValue,
            slug: this.props.slug,
            region: this.props.portalCode
        };
        axios.put(this.props.configurationUrl + "/" + this.props.tenantId +
            "/setting/" +
            settingName, body,
            {
                headers: {
                    'Authorization': 'Jwt ' + this.props.accessToken
                }
            }).then((response) => {
                this.setState({ loading: false });
            }).catch((error) => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    showAlertModal(title, message) {
        this.setState({
            alertModalTitle: title,
            alertModalText: message,
            alertModalVisible: true
        });
    }

    closeAlertModal() {
        this.setState({ alertModalVisible: false });
    }

    deleteTenantSettings() {
        let config = {
            headers: { 'Authorization': 'Jwt ' + this.props.accessToken }
        };
        this.setState({
            errorMessage: "",
            confirmModalVisible: false,
            alertModalVisible: false
        });
        let deleteUrl = this.props.deleteTenantSettingsUrl + "?tenantId=" + this.props.tenantId + "&slug=" + this.props.slug + "&portal=" + this.props.portalCode;
        console.log('calling ' + deleteUrl);
        axios.delete(deleteUrl, config)
            .then(taskResponse => {
                this.showAlertModal("Success", "Tenant settings have been removed.");
            }).catch(error => {
                console.log(error);
                this.showAlertModal("Error", "Unable to remove tenant settings");
            });

        this.loadSettings();
        this.closeConfirmModal();
    }

    addTenantSettings() {
        let config = {
            headers: { 'Authorization': 'Jwt ' + this.props.accessToken }
        };
        let body = {
            portal: this.props.portalCode,
            slug: this.props.slug,
            tenantId: this.props.tenantId,
        };
        this.setState({
            errorMessage: "",
            confirmModalVisible: false,
            alertModalVisible: false
        });
        axios.post(this.props.createTenantSettingsUrl, body, config)
            .then(taskResponse => {
                this.loadSettings();
                this.showAlertModal("Success", "Tenant settings have been created.");
            }).catch(error => {
                console.log(error);
                this.showAlertModal("Error", "Unable to create tenant settings");
            });

        this.closeConfirmModal();
    }

    closeConfirmModal() {
        this.setState({ confirmModalVisible: false });
    }

    openConfirmModal() {
        this.setState({ confirmModalVisible: true });
    }

    render() {
        var recordVideoBlock = <div></div>;
        if (this.state.showVideo) {
            recordVideoBlock =
                <div>
                    <div className="setting">
                        <div className="switch-label">
                            Video Recording
                        </div>
                    </div>
                    <div className="setting">
                        <div className="switch-label">
                            <label htmlFor="recordVideoSwitch">Record Video</label>
                        </div>
                        <Switch id="recordVideoSwitch"
                            borderRadius={9}
                            height={18}
                            width={36}
                            onChange={this.onRecordVideoChanged}
                            checked={this.state.recordVideo} /></div>
                    <div className="setting">
                        <div className="switch-label">
                            <label htmlFor="recordScreenShareSwitch">Record Screen Share</label>
                        </div>
                        <Switch id="recordScreenShareSwitch"
                            borderRadius={9}
                            height={18}
                            width={36}
                            onChange={this.onrecordScreenShareChanged}
                            checked={this.state.recordScreenShare} />
                    </div>
                </div>;
        }
        return (
            <div id="main" className="ui text container transition">
                <div id="tenantsettingsheader" className="wizard-step">
                    <div className="no-top-margin">
                        <div className="ui center aligned container head">
                            <div className="stepheader">
                                <div className="step">Tenant Settings</div>
                                <img src={logo} alt="Dubber MS Teams" className="small-logo" />
                                <div className="step-back"><NavLink tag={Link} to="/">&lt; Back</NavLink>
                                </div>
                                <div className="ui aligned container start">
                                    <div className="step-header">
                                        <h3>Edit Tenant Settings</h3>
                                        <p>
                                            The Tenant settings are the default values that will be used for Compliance Recording.  Settings can be overriden on an individual basis in the user settings.
                                            <br />(Changes are save immediately)<br /><br />
                                            <Button onClick={this.openConfirmModal} className="next-link" to="/">Delete Settings</Button>
                                            <Button onClick={this.addTenantSettings} className="next-link" to="/">Add Default Settings</Button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {(this.state.config !== null && this.state.config.length > 0) &&
                                <div className="ui aligned container start">
                                    <div className="divTable">
                                        <div className="divTableRow">
                                            <div className="divTableHead">
                                                Tenant
                                            </div>
                                            <div className="divTableHead">Settings</div>
                                        </div>
                                    </div>
                                    <div className="settingstable">
                                        <div className="divTable">
                                            <div className="divTableRow">
                                                <div className="divTableCell"><b>{this.props.slug}</b><br /><sub>Tenant Id {this.props.tenantId}</sub></div>
                                                <div className="divTableCell settings-column">
                                                    <div className="setting">
                                                        <div className="switch-label">
                                                            <label title="Give permission to users to pause and resume the calls. It is a separate feature that does not associate to other settings such as Call Classification, Auto start recording or Recording Video" htmlFor="allowPauseResumeSwitch">Allow Pause Resume Calls</label>
                                                        </div>
                                                        <Switch id="allowPauseResumeSwitch"
                                                            borderRadius={9}
                                                            height={18}
                                                            width={36}
                                                            onChange={this.onPermissionPauseResumeCallChanged}
                                                            checked={this.state.permissionPauseResumeCall} />
                                                    </div>
                                                    <br />
                                                    {/*<div className="setting">*/}
                                                    {/*    <div className="switch-label">*/}
                                                    {/*        <label htmlFor="allowDiscardSwitch">Allow Discard Call</label>*/}
                                                    {/*    </div>*/}
                                                    {/*    <Switch id="allowDiscardSwitch"*/}
                                                    {/*            borderRadius={9}*/}
                                                    {/*            height={18}*/}
                                                    {/*            width={36}*/}
                                                    {/*            onChange={this.onPermissionDiscardCallChanged}*/}
                                                    {/*            checked={this.state.permissionDiscardCall} />*/}
                                                    {/*</div>*/}
                                                    {/*<br />*/}
                                                    <div className="setting">
                                                        <div className="switch-label">
                                                            <img src={infoicon} title="If enabled, recording will begin as soon as the call/meeting is answered. When disabled, recording will only commence once the user presses *49 on the dial-pad or clicks the 'record' button within the Dubber Microsoft Teams application." />
                                                            <label htmlFor="onDemandRecordingSwitch">Auto Start Recording</label>
                                                        </div>
                                                        <Switch id="onDemandRecordingSwitch"
                                                            borderRadius={9}
                                                            height={18}
                                                            width={36}
                                                            onChange={this.onOnDemandRecordingChanged}
                                                            checked={!this.state.onDemandRecording} />
                                                    </div>
                                                    <br />
                                                    {recordVideoBlock}
                                                    <br />
                                                    <div className="setting">
                                                        <div className="switch-label">
                                                            Call Classification
                                                        </div>
                                                    </div>
                                                    <div className="setting">
                                                        <div className="switch-label">
                                                            <label title="Record calls that include people from outside, external parties and inside of your organisation." htmlFor="settingSwitchAll">Record All Calls</label>
                                                        </div>

                                                        <Switch id="settingSwitchAll"
                                                            borderRadius={9}
                                                            height={18}
                                                            width={36}
                                                            onChange={this.onRecordAllCallsChanged}
                                                            checked={this.state.recordAllCalls} />
                                                    </div>
                                                    <div className="setting">
                                                        <div className="switch-label">
                                                            ----- OR -----
                                                        </div>
                                                    </div>
                                                    <div className="setting">
                                                        <div className="switch-label">
                                                            <label title="Record calls that include people from outside your organisation. If compliance rules for your organisation make it compulsory to record calls with external parties, select this option or 'Record All Calls'." htmlFor="settingSwitchExternalParties">Record External Calls</label>
                                                        </div>

                                                        <Switch id="settingSwitchExternalParties"
                                                            borderRadius={9}
                                                            height={18}
                                                            width={36}
                                                            onChange={this.onRecordExternalPartiesChanged}
                                                            checked={this.state.recordExternalParties} />
                                                    </div>
                                                    <div className="setting">
                                                        <div className="switch-label">
                                                            <label title="Calls that have more than two participants are classified as meetings." htmlFor="settingSwitchRecordMeetings">Record Meetings</label>
                                                        </div>
                                                        <Switch id="settingSwitchRecordMeetings"
                                                            borderRadius={9}
                                                            height={18}
                                                            width={36}
                                                            onChange={this.onRecordMeetingsChanged}
                                                            checked={this.state.recordMeetings} />

                                                    </div>
                                                    <div className="setting">
                                                        <div className="switch-label">
                                                            <label title="PSTN calls include calls from landlines and cellular networks" htmlFor="settingSwitchRecordPstn">Record PSTN</label>
                                                        </div>
                                                        <Switch id="settingSwitchRecordPstn"
                                                            borderRadius={9}
                                                            height={18}
                                                            width={36}
                                                            onChange={this.onRecordPSTNChanged}
                                                            checked={this.state.recordPSTN} />
                                                    </div>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <label id="errorMessageLabel" className="errorText">{this.state.errorMessage}</label>
                                </div>
                            }
                            <div className="link">
                                <NavLink tag={Link} className="next-link" to="/">Back</NavLink>
                            </div>
                            <div className={this.state.loading ? "spinner-div" : "spinner-hidden"}>
                                <div className="middle-of-page">
                                    <Ring />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <ConfirmModal
                    Visible={this.state.confirmModalVisible}
                    Title="Are you sure?"
                    BodyText="Are you sure you want to remove tenant settings?"
                    OnYes={this.deleteTenantSettings}
                    OnNo={this.closeConfirmModal}
                />
                <AlertModal
                    Title={this.state.alertModalTitle}
                    BodyText={this.state.alertModalText}
                    OnOk={() => { this.setState({ alertModalVisible: false }, this.forceUpdate) }}
                    Visible={this.state.alertModalVisible}
                />
            </div>);

    }
}

export { TenantSettings }