import React, { Component } from 'react';
import axios from "axios";
import { Ring } from "react-awesome-spinners";
import logo from "./DubberMSTeamsSmall.png";
import {NavLink} from "reactstrap";
import {Link} from "react-router-dom";
import Switch from "react-switch";
import {ChatUser} from "./ChatUser";
import {ChatChannel} from "./ChatChannel";

class ChatSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'users',
            recordAllUsers: false,
            recordAllChannels: false,
            userList: [],
            userErrorMessage: '',
            channelList: [],
            channelErrorMessage: '',
            channelLoadedPages: null,
            channelMorePagesAvailable: false,
            loading: false,
            selectAllUsers: false,
            selectAllChannels: false,
            existingRecordedUsers: [],
            existingRecordedChannels: []
        }

        this.setActiveTab = this.setActiveTab.bind(this);
        this.onRecordAllUsersChanged = this.onRecordAllUsersChanged.bind(this);
        this.onRecordAllChannelsChanged = this.onRecordAllChannelsChanged.bind(this);
        this.getUserList = this.getUserList.bind(this);
        this.onUserSearchTextChanged = this.onUserSearchTextChanged.bind(this);
        this.onChannelSearchTextChanged = this.onChannelSearchTextChanged.bind(this);
        this.loadNextPageOfUsersToState = this.loadNextPageOfUsersToState.bind(this);
        this.loadNextPageOfChannelsToState = this.loadNextPageOfChannelsToState.bind(this);
        this.saveChatSettings = this.saveChatSettings.bind(this);
        this.onSave = this.onSave.bind(this);
        this.selectAllUsersChecked = this.selectAllUsersChecked.bind(this);
        this.onUserSelectionChanged = this.onUserSelectionChanged.bind(this);
        this.onChannelSelectionChanged = this.onChannelSelectionChanged.bind(this);
        this.getUserList = this.getUserList.bind(this);
        this.selectAllChannelsChecked = this.selectAllChannelsChecked.bind(this);
    }
    
    componentDidMount() {
        let existingUsers = [];
        let existingChannels = [];
        
        axios.get(this.props.configurationUrl + "/" + this.props.tenantId + "?slug=" + this.props.slug + "&region=" + this.props.portalCode,
            {
                headers: {
                    'Authorization': 'Jwt ' + this.props.accessToken,
                    'Cache-Control': 'no-store'
                }
            }).then((taskResponse) => {
                
                let chatRecordingAppId = taskResponse.data.settings.find(m => m.name === "chatRecordingAppId");
                let recordAllChatUsers = taskResponse.data.settings.find(m => m.name === "recordAllChatUsers");
                let chatRecordingUsers = taskResponse.data.settings.find(m => m.name === "chatRecordingUsers");
                let recordAllChatChannels = taskResponse.data.settings.find(m => m.name === "recordAllChatChannels");
                let chatRecordingChannels = taskResponse.data.settings.find(m => m.name === "chatRecordingChannels");
                    
                if (!chatRecordingAppId || chatRecordingAppId.length === 0) {
                    this.saveChatSettings('chatRecordingAppId', this.props.chatRecordingAppId);
                }

                if (recordAllChatUsers !== undefined) {
                    this.setState({recordAllUsers: recordAllChatUsers.value === 'true'});
                }
                
                if (chatRecordingUsers !== undefined) {
                    existingUsers = chatRecordingUsers.value.split(',');
                    this.setState({existingRecordedUsers: existingUsers}, this.getUserList);
                }
                else {
                    this.getUserList();
                }

                if (recordAllChatChannels !== undefined) {
                    this.setState({recordAllChannels: recordAllChatChannels.value === 'true'});
                }
            
                if (chatRecordingChannels !== undefined) {
                    existingChannels = chatRecordingChannels.value.split(',');
                    this.setState({existingRecordedChannels: existingChannels}, this.getChannelList);
                }
                else {
                    this.getChannelList();
                }
            }
        );
    }
    
    setActiveTab(tabName) {
        this.setState({
            activeTab: tabName
        });
    }

    getUserList(searchTerm = null, skipToken = null) {
        let config = {
        };
        let body = {
            portal: this.props.portalCode,
            tenantId: this.props.tenantId,
            chatRecordingAppId: this.props.chatRecordingAppId,
            searchTerm: searchTerm === undefined ? null : searchTerm,
            skipToken: skipToken
        };
        this.setState({
            userErrorMessage: "",
            loading: true
        });
        axios.post(this.props.aadUsersUrl, body, config)
            .then(taskResponse => {
                this.setState({ loading: false });
                this.setState({
                    skipToken: taskResponse.data.skipToken
                });

                let response = taskResponse.data.users.map(user=> ({ 
                    ...user, 
                    recordingEnabled: this.state.existingRecordedUsers.includes(user.id) 
                }));
                
                if (skipToken === null){
                    this.setState({
                        userList: response,
                    });
                }
                else {
                    let newUserList = this.state.userList.concat(response);
                    this.setState({
                        userList: newUserList,
                    });
                }
                
                if (taskResponse.data === null || taskResponse.data.length === 0) {
                    this.setState({ userErrorMessage: "No users found" });
                }
            }).catch(error => {
            this.setState({ loading: false });
            console.log(error);
            this.setState({ userErrorMessage: "Could not retrieve users" });
        });
    }

    getChannelList(searchTerm = null, page = this.state.channelLoadedPages) {
        let config = {
        };
        let body = {
            portal: this.props.portalCode,
            tenantId: this.props.tenantId,
            chatRecordingAppId: this.props.chatRecordingAppId,
            searchTerm: searchTerm === undefined ? null : searchTerm,
            page: page
        };
        this.setState({
            channelErrorMessage: "",
            loading: true,
            channelLoadedPages: page == null ? 1 : page + 1
        });
        axios.post(this.props.teamsChannelsUrl, body, config)
            .then(taskResponse => {
                this.setState({ loading: false });

                let response = taskResponse.data.channels.map(channel=> ({
                    ...channel,
                    recordingEnabled: this.state.existingRecordedChannels.includes(channel.id)
                }));
                
                this.setState({
                    channelList: this.state.channelList.concat(response),
                    channelMorePagesAvailable: taskResponse.data.hasMorePages
                });

                if (taskResponse.data === null || taskResponse.data.length === 0) {
                    this.setState({ channelErrorMessage: "No channels found" });
                }
            }).catch(error => {
            this.setState({ loading: false });
            console.log(error);
            this.setState({ channelErrorMessage: "Could not retrieve channels" });
        });
    }

    loadNextPageOfUsersToState(event) {
        event.preventDefault();
        this.getUserList(null, this.state.skipToken);
    }

    loadNextPageOfChannelsToState(event) {
        event.preventDefault();
        this.getChannelList(null);
    }

    onRecordAllUsersChanged(checked) {
        let userList = this.state.userList;

        userList.forEach((user) => {
            user.recordingEnabled = false
        });
        
        this.setState({ recordAllUsers: checked, userList: userList }, async () => {
            await this.onSave();
        });
    }

    onRecordAllChannelsChanged(checked) {
        let channelList = this.state.channelList;

        channelList.forEach((channel) => {
            channel.recordingEnabled = false
        });
        
        this.setState({ recordAllChannels: checked, channelList: channelList }, async () => {
            await this.onSave();
        });
    }

    async onUserSearchTextChanged(event) {
        event.preventDefault();

        this.setState({ userList: [] }, 
            this.getUserList(event.target.value)
        );
    }

    async onChannelSearchTextChanged(event) {
        event.preventDefault();
        
        this.setState({
            channelList: [], 
            channelLoadedPages: null
        }, this.getChannelList(event.target.value, 0));
    }
    
    async onSave(){
        this.setState({ loading: true });
        
        if (this.state.recordAllUsers === true) {
            await this.saveChatSettings('recordAllChatUsers', 'true');
            await this.saveChatSettings('chatRecordingUsers', '');       
        } else {
            let usersCombined = this.state.userList
                .filter(user => user.recordingEnabled === true)
                .map(user => user.id)
                .join();

            await this.saveChatSettings('recordAllChatUsers', 'false');
            await this.saveChatSettings('chatRecordingUsers', usersCombined);
        }

        if (this.state.recordAllChannels === true) {
            await this.saveChatSettings('recordAllChatChannels', 'true');
            await this.saveChatSettings('chatRecordingChannels', '');
        } else {
            let channelsCombined = this.state.channelList
                .filter(channel => channel.recordingEnabled === true)
                .map(channel => channel.id)
                .join();

            await this.saveChatSettings('recordAllChatChannels', 'false');
            await this.saveChatSettings('chatRecordingChannels', channelsCombined);
        }

        this.setState({ loading: false });
    }

    selectAllUsersChecked(checked) {
        let userList = this.state.userList;

        userList.forEach((user) => {
            user.recordingEnabled = checked
        });

        this.setState({userList: userList, selectAllUsers: checked}, async () => {
            await this.onSave();
        });
    }

    selectAllChannelsChecked(checked) {
        let channelList = this.state.channelList;

        channelList.forEach((channel) => {
            channel.recordingEnabled = checked
        });

        this.setState({channelList: channelList, selectAllChannels: checked}, async () => {
            await this.onSave();
        });
    }

    async saveChatSettings(settingName, settingValue) {
        let body = {
            settingValue: settingValue,
            slug: this.props.slug,
            region: this.props.portalCode
        };
        await axios.put(this.props.configurationUrl + "/" + this.props.tenantId +
            "/setting/" +
            settingName, body,
            {
                headers: {
                    'Authorization': 'Jwt ' + this.props.accessToken
                }
            }).then((response) => {
        }).catch((error) => {
            console.log(error);
        });
    }
    
    onSelectorChanged(event) {

    }
    
    onUserSelectionChanged(userId, isChecked){
        let userList = this.state.userList;
        userList.forEach((user) => {
            if (user.id === userId) {
                user.recordingEnabled = isChecked;
            }
        });
        this.setState({ userList: userList }, async () => {
            await this.onSave();
        });
    }

    onChannelSelectionChanged(channelId, isChecked){
        let channelList = this.state.channelList;
        channelList.forEach((channel) => {
            if (channel.id === channelId) {
                channel.recordingEnabled = isChecked;
            }
        });
        this.setState({ channelList: channelList }, async () => {
            await this.onSave();
        });
    }

    render() {
        const users = this.state.userList !== null ? this.state.userList.map((user) => {
            return <ChatUser key={user.id} user={user} onValueChanged={this.onUserSelectionChanged} />
        }) : '';
        
        const channels = this.state.channelList !== null ? this.state.channelList.map((channel) => {
            return <ChatChannel key={channel.id} channel={channel} onValueChanged={this.onChannelSelectionChanged} />
        }) : '';
        
        const nextPageOfUsersLink = this.state.skipToken !== null ? <div className="divTableRow">
            <div className="divTableCell column1"></div>
            <div className="divTableCell column2 loadMoreContainer">
                <a href='#' onClick={this.loadNextPageOfUsersToState}>Load More</a>
            </div>
            <div className="divTableCell column3"></div>
        </div> : <></>

        const nextPageOfChannelsLink = this.state.channelMorePagesAvailable !== false ? <div className="divTableRow">
            <div className="divTableCell column1"></div>
            <div className="divTableCell column2 loadMoreContainer">
                <a href='#' onClick={this.loadNextPageOfChannelsToState}>Load More</a>
            </div>
            <div className="divTableCell column3"></div>
        </div> : <></>
        
        return (
            <div id="main" className="ui text container transition">
                <div className="ui center aligned container head">
                    <div className={this.state.loading ? "spinner-div" : "spinner-hidden"}>
                        <div className="middle-of-page">
                            <Ring />
                        </div>
                    </div>
                </div>
                
                <div id="chatsettings" className="wizard-step">
                    <div className="no-top-margin">
                        <div className="ui center aligned container head">
                            <div className="stepheader">
                                <div className="step">Chat Recording Settings</div>
                                <img src={logo} alt="Dubber MS Teams" className="small-logo" />
                                <div className="step-back"><NavLink tag={Link} to="/chatconsent">&lt; Back</NavLink></div>
                                <div className="ui aligned container start">
                                    <div className="step-header">
                                        <h3>Configure Teams Chat Recording</h3>

                                        <button className={this.state.activeTab === 'users' ? 'active-tab' : 'inactive-tab'} onClick={() => {this.setActiveTab('users')}} >Users</button>
                                        <button className={this.state.activeTab === 'channels' ? 'active-tab leftMargin' : 'inactive-tab leftMargin'} onClick={() => {this.setActiveTab('channels')}} >Channels</button>
                                        
                                        <div className='tabContainer'>
                                            <div id='usersTab' className={this.state.activeTab === 'users' ? 'tabPage show' : 'tabPage hide'}>
                                                <div className="switch-label">
                                                    <label htmlFor="recordAllUsersSwitch">Record All Users</label>
                                                </div>
                                                <Switch id="recordAllUsersSwitch"
                                                        borderRadius={9}
                                                        height={18}
                                                        width={36}
                                                        onChange={this.onRecordAllUsersChanged}
                                                        checked={this.state.recordAllUsers} />

                                                <div className={this.state.recordAllUsers === true ? 'show' : 'hide'}>
                                                    All user conversations will be recorded. 
                                                </div>

                                                <div className={this.state.recordAllUsers === false ? 'show' : 'hide'}>
                                                    <div className="ui aligned container start mt-8">
                                                        <div className="divTable">
                                                            <div className="divTableRow">
                                                                <div className="divTableHead column1">
                                                                    Enable Recording<br />
                                                                    <div className="select-all-switch">
                                                                        <Switch id="selectAllSwitch"
                                                                                onChange={this.selectAllUsersChecked}
                                                                                checked={this.state.selectAllUsers}
                                                                                borderRadius={9}
                                                                                height={18}
                                                                                width={36} />
                                                                        <label htmlFor="selectAllSwitch">All</label>
                                                                    </div>
                                                                </div>
                                                                <div className="divTableHead column2">User Name</div>
                                                                <div className="divTableHead settings-column">
                                                                    <div className="fields">
                                                                        <input
                                                                            type='text' 
                                                                            placeholder='Search...' 
                                                                            onKeyDown={(event) => {
                                                                                if (event.key === 'Enter'){
                                                                                    this.onUserSearchTextChanged(event);
                                                                                }
                                                                            }}
                                                                            className={'displayInline'}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="userstable">
                                                            <div className="divTable">
                                                                <div className="divTableRow">
                                                                    <div className="divTableCell column1"></div>
                                                                    <div className="divTableCell column2"></div>
                                                                    <div className="divTableCell column3"></div>
                                                                 </div>
                                                                {users}
                                                                {nextPageOfUsersLink}
                                                            </div>
                                                        </div>
                                                        <label id="errorMessageLabel" className="errorText">{this.state.userErrorMessage}</label>
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div id='channelsTab' className={this.state.activeTab === 'channels' ? 'tabPage show' : 'tabPage hide'}>
                                                <div className="switch-label">
                                                    <label htmlFor="recordAllChannelsSwitch">Record All Channels</label>
                                                </div>
                                                <Switch id="recordAllChannelsSwitch"
                                                        borderRadius={9}
                                                        height={18}
                                                        width={36}
                                                        onChange={this.onRecordAllChannelsChanged}
                                                        checked={this.state.recordAllChannels} />

                                                <div className={this.state.recordAllChannels === true ? 'show' : 'hide'}>
                                                    All channel conversations will be recorded.
                                                </div>

                                                <div className={this.state.recordAllChannels === false ? 'show' : 'hide'}>
                                                    <div className="ui aligned container start mt-8">
                                                        <div className="divTable">
                                                            <div className="divTableRow">
                                                                <div className="divTableHead column1">
                                                                    Enable Recording<br />
                                                                    <div className="select-all-switch">
                                                                        <Switch id="selectAllSwitch"
                                                                                onChange={this.selectAllChannelsChecked}
                                                                                checked={this.state.selectAllChannels}
                                                                                borderRadius={9}
                                                                                height={18}
                                                                                width={36} />
                                                                        <label htmlFor="selectAllSwitch">All</label>
                                                                    </div>
                                                                </div>
                                                                <div className="divTableHead column2">Channel Name</div>
                                                                <div className="divTableHead settings-column">
                                                                    <div className="fields">
                                                                        <input
                                                                            type='text'
                                                                            placeholder='Search...'
                                                                            onKeyDown={(event) => {
                                                                                if (event.key === 'Enter'){
                                                                                    this.onChannelSearchTextChanged(event);
                                                                                }
                                                                            }}
                                                                            className={'displayInline'}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="channelstable">
                                                            <div className="divTable">
                                                                <div className="divTableRow">
                                                                    <div className="divTableCell column1"></div>
                                                                    <div className="divTableCell column2"></div>
                                                                    <div className="divTableCell column3"></div>
                                                                </div>
                                                                {channels}
                                                                {nextPageOfChannelsLink}
                                                            </div>
                                                        </div>
                                                        <label id="errorMessageLabel" className="errorText">{this.state.channelErrorMessage}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    
}

export { ChatSettings }
