import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import logo from './DubberMSTeamsSmall.png';
import Switch from "react-switch"

class PolicyOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alwaysAllowCalls: true,
            playAnnouncements: true,
            recordVideo: false
        };

        this.allowRecordingWhenRecorderUnavailableChanged =
            this.allowRecordingWhenRecorderUnavailableChanged.bind(this);
        this.playCallRecordingAnnouncementsChanged =
            this.playCallRecordingAnnouncementsChanged.bind(this);
        this.recordVideoChanged = this.recordVideoChanged.bind(this);
        this.nextClick = this.nextClick.bind(this);
    }

    allowRecordingWhenRecorderUnavailableChanged(checked) {
        this.setState({ alwaysAllowCalls: checked });
    }

    playCallRecordingAnnouncementsChanged(checked) {
        this.setState({ playAnnouncements: checked });
        this.props.updatePlayAnnouncements(checked);
    }

    recordVideoChanged(checked) {
        this.setState({ recordVideo: checked });
    }

    nextClick(event) {
        this.props.next(this.state.alwaysAllowCalls, this.state.playAnnouncements, this.state.recordVideo);
    }

    render() {
        return (
            <div>
                <div className="ui center aligned container head">
                    <div className="stepheader">
                        <div className="step">Step 3</div>
                        <img src={logo} alt="Dubber MS Teams" className="small-logo" />
                        <div className="step-back"><NavLink tag={Link} to="/">&lt; Back</NavLink></div>
                    </div>
                    <div className="ui aligned container start">
                        <div className="step-header">
                            <h3>Compliance Recording Policy Options</h3>
                            <p>
                                Select below the options for your compliance recording policy.  If you already have a policy created, ensure the options match your current policy and skip to step 5 to select users.
                            </p>
                        </div>
                        <div className="switchfields">
                            <div>
                                <label htmlFor="allowRecordingWhenRecorderUnavailableSwitch">Allow calls when recorders unavailable</label><br/>
                                <sub>Further information can be found in the<a target="_blank" rel="noopener noreferrer" className="download" href="https://docs.microsoft.com/en-us/powershell/module/skype/new-csteamscompliancerecordingapplication?view=skype-ps">Microsoft Documentation</a></sub>
                                <div className="switches">
                                    <Switch onChange={this.allowRecordingWhenRecorderUnavailableChanged} checked={this.state.alwaysAllowCalls} onColor="#7BF"/>
                                </div>
                            </div>
                            <hr/>
                            <div>
                                <label htmlFor="playCallRecordingAnnouncementsSwitch">Play call recording announcement and display recording banner</label><br/>
                                <sub>Depending on your region, you may be required to inform the users they are being recorded</sub>
                                <div className="switches">
                                    <Switch onChange={this.playCallRecordingAnnouncementsChanged} checked={this.state.playAnnouncements} onColor="#7BF"  />
                                </div>
                                <br/>
                                <div style={{ display: !this.state.playAnnouncements ? "block" : "none"}}><br /><i>By proceeding with removing notification, you are deemed to have acknowledged these requirements and accept this ongoing responsibility on behalf of your organisation.  If you do not agree or do not understand these requirements or have any queries generally, please do not proceed with this request and contact us as soon as possible.</i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="link">
                        <button onClick={this.nextClick} className="next-link" >Next</button>
                    </div>
                </div>
            </div>
        );
    }
}

export { PolicyOptions }