import React from 'react';

import axios from "axios";
import logo from './DubberMSTeamsSmall.png';
import infoicon from './InfoIcon.png';
import { Ring } from "react-awesome-spinners";

class ConnectToDubber extends React.Component {
    constructor(props) {
        super(props);
        this.state = ({
            portal: "",
            authId: "",
            authToken: "",
            portalCode: "",
            accountSlug: "",
            loading: false
        });
        this.portalChanged = this.portalChanged.bind(this);
        this.authIdChanged = this.authIdChanged.bind(this);
        this.authTokenChanged = this.authTokenChanged.bind(this);
        this.checkAccount = this.checkAccount.bind(this);
    }

    portalChanged(event) {
        event.preventDefault();
        this.setState(
            {
                portal: event.target.value,
                portalCode: this.mapPortalCode(event.target.value)
            });
    }

    mapPortalCode(name) {
        switch (name) {
            case "Australia":
                return "au";
            case "Canada":
                return "ca";
            case "Europe":
                return "eu";
            case "Japan":
                return "jp";
            case "Singapore":
                return "sg";
            case "United Kingdom":
                return "uk";
            case "Great Britain":
                return "uk1";
            case "United States":
                return "us";
            case "Sandbox":
                return "sandbox";
            case "Development":
                return "dev";
            case "UAT":
                return "uat";
            case "Staging":
                return "in";
            default:
                return "invalid";
        }
    }

    authIdChanged(event) {
        this.setState({ authId: event.target.value });
    }

    authTokenChanged(event) {
        this.setState({ authToken: event.target.value });
    }

    checkAccount(event) {
        this.setState({ loading: true });
        event.preventDefault();
        let config = {
        };
        let body = {
            portal: this.state.portalCode,
            authId: this.state.authId,
            authToken: this.state.authToken
        };
        this.setState({
            errorMessage: ""
        });
        axios.post(this.props.provisionUrl, body, config)
            .then(taskResponse => {
                this.setState({ loading: false });
                
                let childAccounts = null;
                if (taskResponse.data.accountType === "group"){
                    childAccounts = taskResponse.data.accounts.result.accounts;
                }
                
                this.props.portalVerified(
                    this.state.portalCode, 
                    taskResponse.data.dubberAccount,
                    this.state.authId, 
                    this.state.authToken,
                    taskResponse.data.botA, 
                    taskResponse.data.botB,
                    taskResponse.data.jwt, 
                    taskResponse.data.accountType, 
                    childAccounts, 
                    taskResponse.data.chatRecordingAppId, 
                    taskResponse.data.adSyncAppId,
                    taskResponse.data.convenienceBot
                );
            }).catch(error => {
                this.setState({ loading: false });
                console.log(error);
                this.setState({ errorMessage: "Invalid Portal, Auth ID, or Auth Token" });
            });
    }

    render() {
        var editDetails = (
            <div>
                <div className="ui center aligned container head">
                    <div className="stepheader">
                        <div className="step">Step 1</div>
                        <img src={logo} alt="Dubber MS Teams" className="small-logo" />
                        <div className="step-back"><button onClick={this.props.cancel}>&lt; Back</button></div>
                    </div>
                    <div className="ui center aligned container start">
                        <div className="horizontal-layout">
                            <div className="instructions">
                                <h3>To verify your Dubber account, enter your API Auth ID and Auth Token</h3>
                                <img src={infoicon} alt="i"/>
                                <p>Login as a Dubber administrator<br/>Your Auth ID and Token can be found on your dubber account settings -> API tab</p>
                            </div>
                            <div className="column-half">
                                <div className="fields">
                                    <label htmlFor="portalName">Portal: </label>
                                    <input id="portalName" list="portalList" onChange={this.portalChanged
                                    } placeholder="Select" />
                                    <datalist id="portalList" value={this.state.portal}>
                                        <option value="Australia" key="au"></option>
                                        <option value="Canada" key="ca"></option>
                                        <option value="Europe" key="eu"></option>
                                        {/*<option value="Japan" key="jp"></option>*/}
                                        <option value="Singapore" key="sg"></option>
                                        {/*<option value="United Kingdom" key="uk"></option>*/}
                                        <option value="Great Britain" key="uk1"></option>
                                        <option value="United States" key="us"></option>
                                    </datalist>
                                    <label htmlFor="authIdInput">Auth ID: </label>
                                    <input type="text" id="authIdInput" value={this.state.authId} onChange={this.authIdChanged} placeholder="" />
                                    <label htmlFor="authTokenInput">Auth Token: </label>
                                    <input type="password" id="authTokenInput" value={this.state.authToken} onChange={this.authTokenChanged} placeholder="" />
                                    <label id="errorMessageLabel" className="errorText">{this.state.errorMessage}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="link">
                        <button className="next-link" onClick={this.checkAccount} >Authorize Dubber</button>
                    </div>
                    <div className={this.state.loading ? "spinner-div" : "spinner-hidden"}>
                        <div className="middle-of-page">
                            <Ring />
                        </div>
                    </div>
                </div>
            </div>

        );
        return editDetails;
    }
}


export { ConnectToDubber }