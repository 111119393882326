import React from 'react';

import Switch from "react-switch";

class UserToRemove extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: props.user,
            removeUser: false
        };

        this.onUserSelectedToRemove = this.onUserSelectedToRemove.bind(this);
    }

    onUserSelectedToRemove(checked) {
        this.setState({ removeUser: checked });
        this.props.removeUserChanged(checked, this.state.user.externalId);
    }

    render() {
        return (
            <div className="remove-user">
                <div className="remove-user-label"><b>{this.props.user.displayName}</b><br /><sub>{this.props.user.userSlug}</sub></div>
                <Switch id="removeUserSwitch"
                        borderRadius={9}
                        height={18}
                        width={36}
                        onChange={this.onUserSelectedToRemove}
                        checked={this.state.removeUser} />
            </div>
            );
    }

}

export {UserToRemove}