import React from 'react';

import Switch from "react-switch";

class OverrideSetting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            overrideAccountDefaults: this.props.defaultValue !== null,
            settingValue: this.props.defaultValue ?? false
        };

        this.overrideAccountDefaultChanged = this.overrideAccountDefaultChanged.bind(this);
        this.onSettingSwitchChanged = this.onSettingSwitchChanged.bind(this);
    }

    overrideAccountDefaultChanged(event) {
        this.setState({ overrideAccountDefaults: event.target.checked });
        this.props.valueChanged(event.target.checked, this.state.settingValue);
    }

    onSettingSwitchChanged(checked) {
        this.setState({ settingValue: checked });
        this.props.valueChanged(this.state.overrideAccountDefaults, checked);
    }

    render() {
        return <div className="setting">
            <div>Override Account Default</div>
            <div className="settingCheckbox">
                <input
                    type="checkbox"
                    onChange={this.overrideAccountDefaultChanged}
                    defaultChecked={this.state.overrideAccountDefaults}
                    id="useAccountDefaultsPauseResume" />
            </div>
            <div className="switch-label">
                <label htmlFor="settingSwitch" title={this.props.settingLabelTitle}>{this.props.settingLabel}</label>
            </div>

            <Switch id="settingSwitch"
                borderRadius={9}
                height={18}
                width={36}
                disabled={!this.state.overrideAccountDefaults}
                onChange={this.onSettingSwitchChanged}
                checked={this.state.overrideAccountDefaults ? this.state.settingValue : this.props.parentValue} />
        </div>;
    }
}

export {OverrideSetting}