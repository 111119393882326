import React from "react";
import {ChatUser} from "./ChatUser";

class ConfirmModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        }
    }
    
    componentDidMount() {
        this.setState({
            visible: this.props.Visible,
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            visible: nextProps.Visible
        })
    }

    render() {
        
        return (
            <div className="modal" style={{visibility: this.state.visible === true ? 'visible' : 'hidden' }}>
                <div className="modalHeader">
                    {this.props.Title}
                </div>
                <div className="modalBody">
                    <div className="modalBodyText">{this.props.BodyText}</div>
                </div>
                <div className="modalFooter">
                    <button onClick={() => {
                        this.setState({
                            visible: false
                        })
                        if (this.props.OnNo)
                            this.props.OnNo();
                    }} className="muted-link mr-1">No</button>
                    <button onClick={() => {
                        this.setState({
                            visible: false
                        })
                        if (this.props.OnYes)
                            this.props.OnYes();
                    }} className="next-link">Yes</button>
                </div>
            </div>
        )
    }


}

export { ConfirmModal }
