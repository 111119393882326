import React from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import logo from './DubberMSTeamsSmall.png';
import infoicon from './InfoIcon.png';
import copyIcon from './CopyIcon.png';
import axios from "axios";
import { Ring } from "react-awesome-spinners";
import { UserToRemove } from './UserToRemove'

import Switch from "react-switch"

class RemoveUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            playAnnouncements: true,
            alwaysAllowCalls: true,
            confirmDeleteUsersConfiguration: false,
            users: [],
            usersToRemove: []
        }

        this.removeUserChanged = this.removeUserChanged.bind(this);
        this.allowRecordingWhenRecorderUnavailableChanged =
            this.allowRecordingWhenRecorderUnavailableChanged.bind(this);
        this.playCallRecordingAnnouncementsChanged =
            this.playCallRecordingAnnouncementsChanged.bind(this);
        this.confirmDeleteUsersConfigurationChanged = this.confirmDeleteUsersConfigurationChanged.bind(this);
        this.deleteUserConfig = this.deleteUserConfig.bind(this);
    }

    allowRecordingWhenRecorderUnavailableChanged(checked) {
        this.setState({ alwaysAllowCalls: checked });
    }

    playCallRecordingAnnouncementsChanged(checked) {
        this.setState({ playAnnouncements: checked });
    }

    confirmDeleteUsersConfigurationChanged(checked) {
        this.setState({ confirmDeleteUsersConfiguration: checked });
    }

    componentDidMount() {
        this.setState({
            loading: true,
            users: []
        });
        let url = "/provision/tenant/" + this.props.tenantId + "/users?slug=" + this.props.slug + "&region=" + this.props.portalCode
        axios.get(url,
            {
                headers: {
                    'Authorization': 'Jwt ' + this.props.accessToken
                }
            }).then((taskResponse) => {

            var usersConfig = [];
            taskResponse.data.users.forEach((user) => {
                var displayName = user.settings.find(s => s.name === "displayName");
                var userSlug = user.settings.find(s => s.name === "userSlug");

                usersConfig.push({
                    externalId: user.microsoft.userId,
                    displayName: displayName.value,
                    userSlug: userSlug.value
                });
            });

            this.setState({ users: usersConfig, loading: false });

        }).catch(error => {
            console.log(error);
            this.setState({ users: [], loading: false, errorMessage: error.data });
        });

    }

    copyRemoveUsersTextArea(event) {
        var copyText = document.getElementById("removeUsersTextArea");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
    }

    removeUserChanged(checked, externalId) {
        if (checked) {
            let users = this.state.usersToRemove;
            users.push(externalId);
            this.setState({ usersToRemove: users, confirmDeleteUsersConfiguration: false });
        } else {
            let users = [];
            this.state.usersToRemove.forEach((entry) => {
                if (entry !== externalId) {
                    users.push(entry);
                }
            });
            this.setState({ usersToRemove: users, confirmDeleteUsersConfiguration: false });
        }
    }

    deleteUserConfig(event) {
        event.preventDefault();
        if (this.state.usersToRemove.length > 0) {
            this.setState({ loading: true });
            var postData = {
                usersToRemove: this.state.usersToRemove,
                tenantId: this.props.tenantId,
                portalCode: this.props.portalCode,
                slug: this.props.slug
            };

            axios.post(this.props.removeUserConfigUrl, postData, {
                headers: {
                    'Authorization': 'Jwt ' + this.props.accessToken
                }
            }).then(taskResponse => {
                this.setState({
                    loading: false,
                    usersToRemove: [],
                    errorMessage: ""
                });
            }).catch(error => {
                console.log(error);

                this.setState({ errorMessage: (error.response && error.response.data) ? error.response.data : "Error removing users config." });
                this.setState({ loading: false });
            });

        } 
    }

    donothing(event) {

    }

    render() {
        let usersListBlock = this.state.users.map((user) => {
            return <UserToRemove user={user} removeUserChanged={this.removeUserChanged}/>;
        });
        var psCommandRemoveUsers = "";
        if (this.state.usersToRemove.length > 0) {
            var securityGroupName = "MS Teams Dubber Recording Group";
            if (!this.state.playAnnouncements) {
                securityGroupName = securityGroupName + " No Announcements";
            }

            psCommandRemoveUsers = "$groupId = Get-AzureADGroup -All $True | Where-Object {$_.DisplayName -eq \"" + securityGroupName + "\"} | Select -First 1\n";
            this.state.usersToRemove.map((entry) => {
                psCommandRemoveUsers += "Remove-AzureAdGroupMember -ObjectId $groupId.ObjectId -MemberId '" + entry + "' -ErrorAction SilentlyContinue\n";
            });
        }

        return (
            <div id="main" className="ui text container transition">
                <div id="removepolicyheader" className="wizard-step">
                    <div className="no-top-margin">
                        <div className="ui center aligned container head">
                            <div className="stepheader">
                                <div className="step">Remove Users</div>
                                <img src={logo} alt="Dubber MS Teams" className="small-logo" />
                                <div className="step-back"><NavLink tag={Link} to="/">&lt; Back</NavLink>
                                </div>
                                <div className="ui aligned container start">
                                    <div className="step-header">
                                        <h3>Remove User from Compliance Recording Security Group</h3>
                                        <div className="policy-options">
                                            <label htmlFor="allowRecordingWhenRecorderUnavailableSwitch">Allow calls when recorders unavailable.</label><br />
                                            <sub>{this.state.alwaysAllowCalls ? "Calls may still be made if recorders are unavailable" : "Compliance Recorders are required for calls"}</sub>
                                            <div className="policy-switch" >
                                                <Switch id="allowRecordingWhenRecorderUnavailableSwitch"
                                                    borderRadius={9}
                                                    height={18}
                                                    width={36}
                                                    onChange={this.allowRecordingWhenRecorderUnavailableChanged}
                                                    checked={this.state.alwaysAllowCalls} />
                                            </div>
                                        </div>
                                        <div className="policy-options">
                                            <label htmlFor="playCallRecordingAnnouncementsSwitch">Play call recording announcement and display recording banner.</label><br />
                                            <sub>{this.state.playAnnouncements ? "Default Call Recording Announcement will be used" : "Depending on your region, you may be required to inform the users they are being recorded"}</sub>
                                            <div className="policy-switch" >
                                                <Switch id="playCallRecordingAnnouncementsSwitch"
                                                    borderRadius={9}
                                                    height={18}
                                                    width={36}
                                                    onChange={this.playCallRecordingAnnouncementsChanged}
                                                    checked={this.state.playAnnouncements} />
                                            </div>
                                        </div>
                                        <p>The policy options associated with this removal script are Recording Announcements <b>{this.state.playAnnouncements ? "" : "Not"}</b> Enabled and Always Allow Calls {this.state.alwaysAllowCalls ? "Enabled" : "Disabled"}</p>
                                        <h3>Select the users to Remove</h3>
                                        <ol>
                                            <li>Select the users to be removed from the recording policy.  The script below will remove the user from the group associated with the recording options above</li>
                                        </ol>
                                    </div>
                                    <div className="users-list">
                                        {usersListBlock}
                                    </div>

                                    <div className="instructions">
                                        <img src={infoicon} alt="i" />
                                        <br />
                                        <ol start="2">
                                            <li>Remove the Members from the Dubber Recording Group</li>
                                        </ol>
                                    </div>
                                    <div className="column-half">
                                        <div className="space30px">
                                            <textarea id="removeUsersTextArea" className="createPolicy" value={
                                                psCommandRemoveUsers} onChange={this.donothing} >
                                            </textarea>
                                            <button className="copyText" onClick={this
                                                .copyRemoveUsersTextArea} ><img className="copyIcon" src={
copyIcon} alt="" />Copy</button>
                                        </div>
                                    </div>
                                    <div className="external-links">
                                        <sub>View the Microsoft Documentation for<a className="download" target="_blank" rel="noopener noreferrer" href="https://docs.microsoft.com/en-us/powershell/module/azuread/remove-azureadgroupmember?view=azureadps-2.0">Remove-AzureADGroupMember</a></sub>
                                    </div>
                                    <hr />
                                    <div className="space30px">
                                        <div className="confirmationMessage">
                                            <label htmlFor="confirmDeleteUsersConfigurationSwitch">I confirm that I am done with the above scripts and want to delete the user's MS Teams configuration</label>
                                            <div className="policy-switch">
                                                <Switch id="confirmDeleteUsersConfigurationSwitch"
                                                        onChange={this.confirmDeleteUsersConfigurationChanged}
                                                        checked={this.state.confirmDeleteUsersConfiguration} />
                                            </div>
                                        </div>
                                        <div className="confirmationMessage">
                                            <sub>Once deleted, the user will not appear in the above list.</sub>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={this.state.usersToRemove.length === 0 && this.state.confirmDeleteUsersConfiguration ? "link" : "hidden-wizard-step"}>
                                <NavLink tag={Link} className="next-link" to="/">Back</NavLink>
                            </div>
                            <div className={this.state.usersToRemove.length !== 0 && this.state.confirmDeleteUsersConfiguration ? "link" : "hidden-wizard-step"}>
                                <button onClick={this.deleteUserConfig} className="next-link" >Delete</button>
                            </div>
                            <div className={!this.state.confirmDeleteUsersConfiguration ? "link" : "hidden-wizard-step"}>
                                <p>Confirm PowerShell scripts have been run</p>
                            </div>
                            <div className={this.state.loading ? "spinner-div" : "spinner-hidden"}>
                                <div className="middle-of-page">
                                    <Ring />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export { RemoveUsers }