import React from "react"
import logo from './DubberMSTeams.png';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

class FinishWizard extends React.Component {
    render() {
        var portalLink = "https://" + this.props.portalCode + ".dubber.net/login";
        if (this.props.portalCode === "uat") portalLink = "https://puddles.dubber.net/login";
        if (this.props.portalCode === "dev") portalLink = "https://dev.dubber.io/login";
        if (this.props.portalCode === "in") portalLink = "https://stg.dubber.io/login";
        return (
            <div id="main" className="ui text container transition">
                <div id="removepolicyheader" className="wizard-step">
                    <div className="ui center aligned container head">
                        <div className="stepheader">
                            <div className="step">Finished</div>
                            <img src={logo} alt="Dubber MS Teams" />
                            <div className="step-back"><NavLink tag={Link} to="/">&lt; Home</NavLink>
                            </div>
                        </div>
                        <div className="ui center aligned container intro">
                            <div className="finish">
                                <h1>Congratulations</h1>
                                <h2>The Dubber recorders are now active</h2>
                            </div>
                        </div>
                        <div className="link">
                            <a href={portalLink}>View Account</a>
                        </div>
                    </div>
                </div>
            </div>);
    }
}

export { FinishWizard }