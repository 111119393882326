import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import logo from './DubberMSTeams.png';
//import infoicon from './InfoIcon.png';
import newPolicy from './NewPolicy.png';
import removePolicy from './RemovePolicy.png';
import addUsers from './AddUsers.png';
import removeUsers from './RemoveUsers.png';
import userSettings from './UserSettings.png';

export class Home extends Component {
    static displayName = Home.name;

    TenantAllowedForChat(tenantId) {
        if (tenantId === 'ec4ce914-d629-404c-8916-44a9ff728b7d' ||
            tenantId === '62f3cbd2-be61-4f76-aa15-7265241470a8' ||
            tenantId === 'cdf97d49-7267-4f91-8cf3-9e3f53ce2ef3' ||
            tenantId === '943d83a6-bfb4-4579-9258-700d891a2efc' ||
            tenantId === '818f2bb7-c663-4783-88eb-a7742e2d517e' ||
            tenantId === '15d8cd94-4fa7-4937-aa0c-ef6a5969c073' ||
            tenantId === '896e9455-a708-4bf7-be60-a34063d27bfa' || // Sandbox 
            tenantId === '3560e289-5f74-4ccc-923c-93e25db357a6' || // UAT
            tenantId === '40a1de49-261a-4784-8d06-67e57fba0186' || // AU
            tenantId === '1dd8d4ab-c281-4982-992e-c0036c9bff72') { // Westpac
            return true;
        }
        return false;
    }

    TenantAllowedForAdSync(tenantId) {
        return true;
    }

    render() {
        let msteamChatRecordingDecision = '';
        let adSyncSettingsDecision = '';

        if (this.TenantAllowedForChat(this.props.tenantId)) {
            msteamChatRecordingDecision = <div className="decision-button-div-row">
                <div className="home-page-decision">
                    <div className="home-page-decision-img">
                        <img src={userSettings} alt="Chat Recording" />
                    </div>
                    <div className="home-page-decision-text">
                        Configure Chat<br />Recording Settings
                                          </div>
                    <NavLink tag={Link} className="decision-button" to="/chatconsent">Chat Recording</NavLink>
                </div>
            </div>;
        }

        if (this.TenantAllowedForAdSync(this.props.tenantId)) {
            adSyncSettingsDecision = <div className="decision-button-div-row">
                <div className="home-page-decision">
                    <div className="home-page-decision-img">
                        <img src={userSettings} alt="AD Sync" />
                    </div>
                    <div className="home-page-decision-text">
                        Configure Active Directory<br />Sync Settings
                    </div>
                    <NavLink tag={Link} className="decision-button" to="/adsyncconsent">AD Sync</NavLink>
                </div>
            </div>;
        }
        
        return (
          <div id="main" className="ui text container transition">
              <div className="wizard-step">
                  <div>
                      <div className="ui center aligned container head">
                          <div className="homeheader">
                              <div className="step homestep">Home</div>
                              <img src={logo} alt="Dubber MS Teams" className="small-logo" />
                              <div className="step-logout"><button onClick={this.props.cancel}>&lt; Logout</button></div>
                          </div>
                          <div className="ui aligned container start">
                              <div className="decision-button-div">
                                  <div className="decision-button-div-row">
                                      <div className="home-page-decision">
                                          <div className="home-page-decision-img">
                                              <img src={newPolicy} alt="New Policy" />
                                          </div>
                                          <div className="home-page-decision-text">
                                              Create a New Policy to connect Microsoft Teams to Dubber
                                          </div>
                                          <NavLink tag={Link} className="decision-button" to="/newpolicy">New Policy</NavLink>
                                      </div>
                                      <div className="home-page-decision">
                                          <div className="home-page-decision-img">
                                              <img src={removePolicy} alt="Remove Policy" />
                                          </div>
                                          <div className="home-page-decision-text">
                                              Remove an Existing Policy to Disconnect Dubber Recording
                                          </div>
                                          <NavLink tag={Link} className="decision-button" to="/removepolicy">Remove Policy</NavLink>
                                      </div>
                                      <div className="home-page-decision">
                                          <div className="home-page-decision-img">
                                              <img src={addUsers} alt="Add Users" />
                                          </div>
                                          <div className="home-page-decision-text">
                                              Add new users to an<br />Existing Policy
                                          </div>
                                          <NavLink tag={Link} className="decision-button" to="/addusers">Add Users</NavLink>
                                    </div>
                                  </div>
                                  <div className="decision-button-div-row">
                                      <div className="home-page-decision">
                                          <div className="home-page-decision-img">
                                              <img src={removeUsers} alt="Remove Users" />
                                          </div>
                                          <div className="home-page-decision-text">
                                              Remove users from an<br />Existing Recording Policy
                                          </div>
                                          <NavLink tag={Link} className="decision-button" to="/removeusers">Remove Users</NavLink>
                                      </div>
                                      <div className="home-page-decision">
                                          <div className="home-page-decision-img">
                                              <img src={userSettings} alt="Tenant Settings" />
                                          </div>
                                          <div className="home-page-decision-text">
                                              Change Tenant<br />Recording Settings
                                          </div>
                                          <NavLink tag={Link} className="decision-button" to="/tenantsettings">Tenant Settings</NavLink>
                                      </div>
                                      <div className="home-page-decision">
                                          <div className="home-page-decision-img">
                                              <img src={userSettings} alt="User Settings" />
                                          </div>
                                          <div className="home-page-decision-text">
                                              Change User<br />Recording Settings
                                          </div>
                                          <NavLink tag={Link} className="decision-button" to="/usersettings">User Settings</NavLink>
                                      </div>
                                    </div>
                                    {msteamChatRecordingDecision}
                                    {adSyncSettingsDecision}
                              </div>
                          </div>
                          <div className="disclaimer">
                              <h3 className="disclaimer">Disclaimer</h3>
                              <p>This provisioning tool is intended to assist administrators with creating a recording policy in MS teams and provisioning users in Dubber. It is aimed to assist in setting up Dubber call recording where the MS teams set-up is standard. In the event that the MS teams implementation has been highly customised, there are no guarantees that this tool will be free of errors.</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      );
  }
}
