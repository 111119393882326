import React from 'react';
import Switch from "react-switch"

class PolicyUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            objectId: props.user.externalIdentifier,
            provisionedForRecording: props.user.provisionForRecording,
            products: props.products,
            product: props.user.product,
            groupProduct: "",
            addUser: false
        }
        this.userProductChanged = this.userProductChanged.bind(this);
        this.addUserChecked = this.addUserChecked.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (typeof nextProps.user != "undefined") {
            if (nextProps.user.reloaded) {
                nextProps.user.reloaded = false;
                this.setState({
                    user: nextProps.user,
                    provisionedForRecording: nextProps.user.provisionForRecording,
                    product: nextProps.user.product,
                    products: nextProps.products,
                    groupProduct: nextProps.groupProduct,
                    addUser: false
                });
            } else if (nextProps.selectAll) {
                if (!nextProps.user.provisionForRecording) {
                    this.setState({
                        addUser: nextProps.user.externalGroup !== null,
                        user: nextProps.user,
                        products: nextProps.products,
                        groupProduct: nextProps.groupProduct,
                        product: nextProps.user.product
                    });
                }
            } else if (!nextProps.user.provisionForRecording && nextProps.user.product === "") {
                this.setState({
                    addUser: false,
                    user: nextProps.user,
                    groupProduct: nextProps.groupProduct,
                    products: nextProps.products,
                    product: ""
                });
            } else {
                this.setState({
                    user: nextProps.user,
                    provisionedForRecording: nextProps.user.provisionForRecording,
                    products: nextProps.products,
                    groupProduct: nextProps.groupProduct,
                    product: nextProps.user.product
                });
            } 
        }
    }

    userProductChanged(event) {
        if (this.state.provisionedForRecording || !this.state.addUser || (this.state.addUser && event.target.value === "")) {
            return;
        }

        var product = event.target.value;
        var available = this.props.availableLicenses(product);
        var total = this.props.totalLicenses(product);
        if (available >= total) return;

        this.setState({ product: event.target.value });
        this.props.onValueChanged(this.props.user.externalIdentifier, event.target.value);
    }

    addUserChecked(checked) {
        if (checked) {
            if (this.props.accountType === "group") {
                if (this.state.groupProduct !== "") {
                    this.setState({ addUser: checked, product: this.state.groupProduct, errorMessage: "" });
                    this.props.addUserChecked(this.props.user, checked, this.state.groupProduct);
                } else {
                    this.setState({ errorMessage: "No product selected" });
                }
            } else {
                var licenseFound = false;
                for (var i = 0; i < this.props.products().length; i++) {
                    var product = this.props.products()[i];
                    var available = this.props.availableLicenses(product);
                    var total = this.props.totalLicenses(product);
                    if (available < total) {
                        this.setState({ addUser: checked, product: product, errorMessage: "" });
                        this.props.addUserChecked(this.props.user, checked, product);
                        licenseFound = true;
                        break;
                    }
                }

                if (!licenseFound) {
                    this.setState({ errorMessage: "No available license." });
                }
            }
        } else {
            this.props.addUserChecked(this.props.user, checked, "");
            this.setState({ addUser: checked, product: "", errorMessage: "" });
        }
    }

    render() {
        var addCheckbox = <div><Switch id={this.state.objectId} onChange={this.addUserChecked} checked={this.state.addUser} /></div>;
        if (this.state.provisionedForRecording) {
            addCheckbox = <div></div>;
        }
        var productOptionsBlock = "";
        
        var groupHideClass = "";
        if (this.props.accountType === "group") {
            groupHideClass = "invisibleElement";
        }
        
        if (this.props.accountType !== "group") {
            var productOptions = this.state.products().map((product) =>
                <option key={product} value={product}>
                {this.props.productName(product)}
            </option>
            );
            productOptionsBlock =
                <select value={this.state.product} onChange={this.userProductChanged} disabled={this.state
                    .provisionedForDubber} id={this.state.ObjectId} >
                <option key="" value=""></option>
                {productOptions}
            </select>;

        }
        return (
            <div className="divTableRow" key={this.state.ObjectId}>
                <div className="divTableCell column1">
                    {addCheckbox}
                </div>
                <div className="divTableCell column2">
                    {this.props.user.firstName} {this.props.user.lastName} <br /> <sub>{this.props.user.username}</sub>
                </div>
                <div className="divTableCell column3">
                    <span className={groupHideClass}>
                        {productOptionsBlock}
                    </span>
                </div>
                <div className="divTableCell column4 centered">
                    <input type="checkbox" defaultChecked={this.state.provisionedForRecording} disabled={true} />
                    <div className="errorText">{this.state.errorMessage}</div>
                </div>
            </div>
        );

    }
}

export { PolicyUser }