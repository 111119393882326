import React, { Component } from 'react';
import axios from "axios";
import { DiscoverUsers } from './DiscoverUsers';
import { SelectUsers } from './SelectUsers';
import { AddUsersToAzure } from './AddUsersToAzure';
import { Ring } from "react-awesome-spinners";

class AddUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step5visible: true,
            step6visible: false,
            loading: false,
            users: [],
            subscriptions: [],
            alwaysAllowCalls: this.props.alwaysAllowCalls, 
            playAnnouncements: this.props.playAnnouncements,
        }
        this.usersUploaded = this.usersUploaded.bind(this); 
        this.usersSelected = this.usersSelected.bind(this);
        this.cancelStep6 = this.cancelStep6.bind(this);
        this.cancelStep7 = this.cancelStep7.bind(this);
        this.availableProducts = this.availableProducts.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.playAnnouncements !== undefined) {
            this.setState(
                {
                    playAnnouncements: nextProps.playAnnouncements,
                    alwaysAllowCalls: nextProps.alwaysAllowCalls
                });
        }
    }

    usersUploaded(users, subscriptions, file) {
        this.setState({
            step5visible: false,
            step6visible: true,
            users: users,
            subscriptions: subscriptions,
            csvFile: file
        });
    }

    cancelStep6(event) {
        event.preventDefault();
        this.setState({ step5visible: true, step6visible: false });
    }

    usersSelected(newUsers) {
        this.setState({
            step6visible: false,
            step7visible: true,
            newUsers: newUsers
        });
    }

    availableProducts() {
        var result = [];
        if (this.state.subscriptions)
            this.state.subscriptions.forEach(sub => result.push(sub.product));
        return result;
    }

    cancelStep7(event) {
        event.preventDefault();
        if (this.state.csvFile) {
            this.setState({ loading: true });

            const formData = new FormData();
            formData.append("file", this.state.csvFile, "ReloadUsers.csv");
            formData.append("portalCode", this.props.portalCode);
            formData.append("slug", this.props.slug);
            formData.append("accountType", this.props.accountType);
            axios.post(this.props.processUsersUrl,
                formData,
                { headers: { 'Authorization': 'Jwt ' + this.props.accessToken } }).then(taskResponse => {
                if (taskResponse.data.users) {
                    for (var i = 0; i < taskResponse.data.users.length; i++) {
                        var user = taskResponse.data.users[i];
                        user.reloaded = true;
                    }
                }
                this.setState({
                    step6visible: true,
                    step7visible: false,
                    users: taskResponse.data.users,
                    subscriptions: taskResponse.data.subscriptions,
                    loading: false
                });
            }).catch(error => {
                this.setState({ loading: false, step5visible: true, step7visible: false });

                console.log(error);
            });

        } else {
            this.setState({ step6visible: true, step7visible: false, loading: false });
        }
    }

    render() {
        return (
            <div id="main" className="ui text container transition">
                <div id="step5header" className={this.state.step5visible ? 'wizard-step' : 'hidden-wizard-step'}>
                    <DiscoverUsers
                        next={this.usersUploaded}
                        processUsersUrl={this.props.processUsersUrl}
                        portalCode={this.props.portalCode}
                        slug={this.props.slug}
                        accessToken={this.props.accessToken}
                        accountType={this.props.accountType}
                    />
                </div>
                <div id="step6header" className={this.state.step6visible ? 'wizard-step' : 'hidden-wizard-step'}>
                    <SelectUsers
                        next={this.usersSelected}
                        cancel={this.cancelStep6}
                        users={this.state.users}
                        products={this.availableProducts}
                        subscriptions={this.state.subscriptions}
                        portalCode={this.props.portalCode}
                        slug={this.props.slug}
                        tenantId={this.props.tenantId}
                        provisionUsersUrl={this.props.provisionUsersUrl}
                        accessToken={this.props.accessToken}
                        accountType={this.props.accountType}
                    />
                </div>
                <div id="step7header" className={this.state.step7visible ? 'wizard-step' : 'hidden-wizard-step'}>
                    <AddUsersToAzure
                        cancel={this.cancelStep7}
                        newUsers={this.state.newUsers}
                        users={this.state.users}
                        alwaysAllowCalls={this.state.alwaysAllowCalls}
                        playAnnouncements={this.state.playAnnouncements}
                    />
                </div>
                <div className="ui center aligned container head">
                    <div className={this.state.loading ? "spinner-div" : "spinner-hidden"}>
                        <div className="middle-of-page">
                            <Ring />
                        </div>
                    </div>
                </div>
            </div>
            );
    }
}

export { AddUsers }