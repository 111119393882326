//import 'bootstrap/dist/css/bootstrap.css';
//import './components/dataTables.semanticui.min.css'
//import './components/semantic.min.css'
//import './components/wxc.css'
//import './components/all.css'

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { unregister } from './registerServiceWorker';

const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter>
        <App provisionUrl="/provision/checkaccount"
            processUsersUrl="/provision/processusers"
            provisionUsersUrl="/provision/provisionusers"
            confirmConsentUrl="/provision/confirmconsent"
            configurationUrl="/provision/configuration"
            removeUserConfigUrl="/provision/clearconfig"
            impersonateChildAccountUrl="/provision/impersonate"
            aadUsersUrl="/provision/getAadUsers" 
            teamsChannelsUrl="/provision/getTeamsChannels"
            aadGroupsUrl="/provision/getAadGroups"
            adSyncUrl="/provision/adsync" 
            productCodeValidationUrl="/provision/validateproductcode" 
            availableProductCodesUrl="/provision/availableproductcodes"
            createTenantSettingsUrl="/provision/createtenantsettings"
            deleteTenantSettingsUrl="/provision/deletetenantsettings"
        />
    </BrowserRouter>,
  rootElement);

unregister();


