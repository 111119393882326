import React from 'react';
import { PolicyOptions } from './PolicyOptions';
import { CreatePolicy } from './CreatePolicy';
import { ConsentPermissions } from './ConsentPermissions';

class NewPolicy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step3visible: true,
            step4visible: false,
            step5visible: false,
            alwaysAllowCalls: true,
            playAnnouncements: true,
            recordVideo: false
        };

        this.cancelStep4 = this.cancelStep4.bind(this);
        this.cancelStep5 = this.cancelStep5.bind(this);
        this.policyOptionsSelected = this.policyOptionsSelected.bind(this);
        this.policyCreated = this.policyCreated.bind(this);
        this.permissionConsented = this.permissionConsented.bind(this);
    }

    cancelStep4(event) {
        event.preventDefault();
        this.setState({ step3visible: true, step4visible: false });
    }

    cancelStep5(event) {
        event.preventDefault();
        this.setState({ step4visible: true, step5visible: false });
    }

    policyOptionsSelected(alwaysAllowCalls, playAnnouncements, recordVideo) {
        this.setState({
            step3visible: false,
            step4visible: true,
            alwaysAllowCalls: alwaysAllowCalls,
            playAnnouncements: playAnnouncements,
            recordVideo: recordVideo
        });
    }

    policyCreated() {
        this.setState({
            step4visible: false,
            step5visible: true
        });
    }

    permissionConsented() {
        this.setState({
            step8visible: false,
            step9visible: true
        });
    }

    render() {
        return (
            <div id="main" className="ui text container transition">
                <div id="step3header" className={this.state.step3visible ? 'wizard-step' : 'hidden-wizard-step'}>
                    <PolicyOptions next={this.policyOptionsSelected}
                        updatePlayAnnouncements={this.props.updatePlayAnnouncements} />
                </div>
                <div id="step4header" className={this.state.step4visible ? 'wizard-step' : 'hidden-wizard-step'}>
                    <CreatePolicy next={this.policyCreated}
                                  cancel={this.cancelStep4}
                                  alwaysAllowCalls={this.state.alwaysAllowCalls}
                                  playAnnouncements={this.state.playAnnouncements}
                                  recordVideo={this.state.recordVideo}
                                  botA={this.props.botA}
                                  botB={this.props.botB}
                                  portal={this.props.portalCode}
                                  tenantId={this.props.tenantId}
                                  updatePlayAnnouncements={this.updateplayAnnouncements}  
                                  domainName={this.props.domainName}
                    />
                </div>
                <div id="step5visible" className={this.state.step5visible ? 'wizard-step' : 'hidden-wizard-step'}>
                    <ConsentPermissions
                        next={this.permissionConsented}
                        cancel={this.cancelStep5}
                        botA={this.props.botA}
                        botB={this.props.botB}
                        convenienceBot={this.props.convenienceBot}
                        confirmConsentUrl={this.props.confirmConsentUrl}
                        portal={this.props.portalCode}
                        tenantId={this.props.tenantId}
                        slug={this.props.slug}
                        config={this.props.config}
                        accessToken={this.props.accessToken}
                    />
                </div>

            </div>
        );
    }
}

export {NewPolicy}