import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import logo from './DubberMSTeamsSmall.png';
import infoicon from './InfoIcon.png';
import copyIcon from './CopyIcon.png';
import Switch from "react-switch"

class RemovePolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            domainName: this.props.domainName,
            tenantId: this.props.tenentId,
            playAnnouncements: true,
            alwaysAllowCalls: true,
            recordVideo: this.props.recordVideo
        }
        this.allowRecordingWhenRecorderUnavailableChanged =
            this.allowRecordingWhenRecorderUnavailableChanged.bind(this);
        this.playCallRecordingAnnouncementsChanged =
            this.playCallRecordingAnnouncementsChanged.bind(this);
    }

    allowRecordingWhenRecorderUnavailableChanged(checked) {
        this.setState({ alwaysAllowCalls: checked });
    }

    playCallRecordingAnnouncementsChanged(checked) {
        this.setState({ playAnnouncements: checked });
    }

    copyRemovePolicyTextArea(event) {
        var copyText = document.getElementById("removePolicyTextArea");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
    }

    copyRemovePolicyAssignmentTextArea(event) {
        var copyText = document.getElementById("removePolicyAssignmentTextArea");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
    }

    copyRecordingSecurityGroupTextArea(event) {
        var copyText = document.getElementById("removeRecordingSecurityGroupTextArea");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
    }

    copyRemoveAppInstancesTextArea(event) {
        var copyText = document.getElementById("removeAppInstancesTextArea");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
    }

    donothing(event) {

    }

    render() {
        var policyName = "Dubber" + this.state.domainName + "RP";
        if (!this.state.playAnnouncements || this.state.recordVideo) {
            policyName = policyName + ".";
            if (!this.state.playAnnouncements) {
                policyName = policyName + "NA";
            }
            if (this.state.recordVideo) {
                policyName = policyName + "V";
            }
        }

        var securityGroupName = "MS Teams Dubber Recording Group";
        if (!this.state.playAnnouncements || this.state.recordVideo) {
            if (!this.state.playAnnouncements) {
                securityGroupName = securityGroupName + " No Announcements";
            }
            if (this.state.recordVideo) {
                securityGroupName = securityGroupName + " with Video";
            }
        }

        var psCommandRemoveGroupPolicyAssignment = "$recordingGroup = Get-AzureADGroup -All $True | Where-Object {$_.DisplayName -eq '" + securityGroupName + "'} | Select -First 1 \n" +
            "if ($recordingGroup -ne $null)\n" +
            "{\n" +
            "    Remove-CsGroupPolicyAssignment -GroupId $recordingGroup.ObjectId -PolicyType TeamsComplianceRecordingPolicy -ErrorAction SilentlyContinue\n}\n";

        var psCommandRemovePolicy = "$complianceRecordingPolicies = Get-CsTeamsComplianceRecordingPolicy -ErrorAction SilentlyContinue | select Identity \n" +
            "Foreach ($compliancePolicy in $complianceRecordingPolicies)\n{\n" +
            "    $currentPolicyIdentity = $compliancePolicy.Identity\n" +
            "    if ($currentPolicyIdentity -eq 'Tag:" + policyName + "')\n" +
            "    {\n        Remove-CsTeamsComplianceRecordingPolicy -Identity $compliancePolicy.Identity\n" +
            "        Write-Output \"Removing Compliance Recording Policy $currentPolicyIdentity\" \n" +
            "    }\n    else\n    {\n" +
            "        Write-Output \"Skipping Removal of Compliance Recording Policy $currentPolicyIdentity\"\n" +
            "    }\n}\n";

        var psCommandRemoveSecurityGroup = "$recordingGroups = Get-AzureADGroup -All $True | Where-Object {$_.DisplayName -eq '" + securityGroupName + "'} \n" +
            "Foreach($recordingGroup in $recordingGroups)\n" +
            "{\n    if ($recordingGroup -ne $null)\n" + "" +
            "    {\n        Remove-AzureADGroup -ObjectId $recordingGroup.ObjectId\n    }\n}\n";

        var psCommandRemoveAppInstances = "$CurrentInstances = Get-CsOnlineApplicationInstance\n" + "Foreach($instance in $CurrentInstances)\n" +
            "{\n" +
            "    if ($instance.DisplayName.tolower().StartsWith(\"dubberbot\")) {\n" +
            "        Remove-AzureADUser -ObjectId $instance.ObjectId\n" +
            "    }\n" +
            "}\n";


        return (
            <div id="main" className="ui text container transition">
                <div id="removepolicyheader" className="wizard-step">
                    <div className="no-top-margin">
                        <div className="ui center aligned container head">
                            <div className="stepheader">
                                <div className="step">Remove Policy</div>
                                <img src={logo} alt="Dubber MS Teams" className="small-logo" />
                                <div className="step-back"><NavLink tag={Link} to="/">&lt; Back</NavLink>
                                </div>
                                <div className="ui aligned container start">
                                    <div className="step-header">
                                        <h3>Remove Compliance Recording Policy</h3>
                                        <p>
                                            The scripts on this page will assist you in removing your existing policy.
                                        </p>
                                        <div className="policy-options">
                                            <label htmlFor="allowRecordingWhenRecorderUnavailableSwitch">Allow calls when recorders unavailable.</label><br />
                                            <sub>{this.state.alwaysAllowCalls ? "Calls may still be made if recorders are unavailable" : "Compliance Recorders are required for calls"}</sub>
                                            <div className="policy-switch" >
                                                <Switch id="allowRecordingWhenRecorderUnavailableSwitch"
                                                    borderRadius={9}
                                                    height={18}
                                                    width={36}
                                                    onChange={this.allowRecordingWhenRecorderUnavailableChanged}
                                                    checked={this.state.alwaysAllowCalls} />
                                            </div>
                                        </div>
                                        <div className="policy-options">
                                            <label htmlFor="playCallRecordingAnnouncementsSwitch">Play call recording announcement and display recording banner.</label><br />
                                            <sub>{this.state.playAnnouncements ? "Default Call Recording Announcement will be used" : "Depending on your region, you may be required to inform the users they are being recorded"}</sub>
                                            <div className="policy-switch" >
                                                <Switch id="playCallRecordingAnnouncementsSwitch"
                                                    borderRadius={9}
                                                    height={18}
                                                    width={36}
                                                    onChange={this.playCallRecordingAnnouncementsChanged}
                                                    checked={this.state.playAnnouncements} />
                                            </div>
                                        </div>
                                        <p>The policy options associated with this removal script are Recording Announcements <b>{this.state.playAnnouncements ? "" : "Not"}</b> Enabled and Always Allow Calls {this.state.alwaysAllowCalls ? "Enabled" : "Disabled"}</p>
                                    </div>
                                    <div className="instructions">
                                        <img src={infoicon} alt="i" />
                                        <br />
                                        <ol>
                                            <li>Remove the Compliance Recording Group Policy Assignment</li>
                                        </ol>
                                    </div>
                                    <div className="column-half">
                                        <div className="space30px">
                                            <textarea id="removePolicyAssignmentTextArea" className="createPolicy" value={psCommandRemoveGroupPolicyAssignment} >
                                            </textarea>
                                            <button className="copyText" onClick={this.copyRemovePolicyAssignmentTextArea} ><img className="copyIcon" src={copyIcon} alt="" />Copy</button>
                                        </div>
                                    </div>
                                    <div className="external-links">
                                        <sub>View the Microsoft Documentation for<a className="download" target="_blank" rel="noopener noreferrer" href="https://docs.microsoft.com/en-us/powershell/module/teams/remove-csgrouppolicyassignment?view=teams-ps">Remove-CsGroupPolicyAssignment</a></sub>
                                    </div>
                                    <hr />
                                    <div className="instructions">
                                        <ol start="2">
                                            <li>Remove the Recording Security Group</li>
                                        </ol>
                                    </div>
                                    <div className="column-half">
                                        <div className="space20px">
                                            <textarea id="removeRecordingSecurityGroupTextArea" className="createAppInstance" value={psCommandRemoveSecurityGroup} onClick={this.donothing}>

                                            </textarea>
                                            <button className="copyText" onClick={this.copyRecordingSecurityGroupTextArea} ><img className="copyIcon" src={copyIcon} alt="" />Copy</button>
                                        </div>
                                    </div>
                                    <div className="external-links">
                                        <sub>View the Microsoft Documentation for<a className="download" target="_blank" rel="noopener noreferrer" href="https://docs.microsoft.com/en-us/powershell/module/azuread/remove-azureadgroup?view=azureadps-2.0">Remove-AzureADGroup</a></sub>
                                    </div>
                                    <hr />
                                    <div className="instructions">
                                        <ol start="3">
                                            <li>Remove Application Instances for the Dubber Recorders</li>
                                        </ol>
                                    </div>
                                    <div className="column-half">
                                        <div className="space20px">
                                            <textarea id="removePolicyTextArea" className="createPolicy" value={psCommandRemovePolicy} >
                                            </textarea>
                                            <button className="copyText" onClick={this.copyRemovePolicyTextArea} ><img className="copyIcon" src={copyIcon} alt="" />Copy</button>
                                        </div>
                                    </div>
                                    <div className="external-links">
                                        <sub>View the Microsoft Documentation for<a className="download" target="_blank" rel="noopener noreferrer" href="https://docs.microsoft.com/en-us/powershell/module/skype/remove-csteamscompliancerecordingpolicy?view=skype-ps">Remove-CsTeamsComplianceRecordingPolicy</a></sub>
                                    </div>

                                    <hr />

                                    <div className="instructions">
                                        <ol start="4">
                                            <li>Remove the Recorders Application Instances</li>
                                        </ol>
                                    </div>
                                    <div className="column-half">
                                        <div className="space20px">
                                            <textarea id="removeAppInstancesTextArea" className="createRecordingGroup" value={psCommandRemoveAppInstances}>

                                            </textarea>
                                            <button className="copyText" onClick={this.copyRemoveAppInstancesTextArea} ><img className="copyIcon" src={copyIcon} alt="" />Copy</button>
                                        </div>
                                    </div>
                                    <div className="external-links">
                                        <sub>View the Microsoft Documentation for<a className="download" target="_blank" rel="noopener noreferrer" href="https://docs.microsoft.com/en-us/powershell/module/azuread/remove-azureaduser?view=azureadps-2.0">Remove-AzureADUser</a></sub><br />
                                    </div>
                                </div>
                                <div className="link">
                                    <NavLink tag={Link} className="next-link" to="/">Back</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export { RemovePolicy }