import React from 'react';
import Switch from "react-switch"

class ChatUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            objectId: props.user.id,
            user: props.user,
            isChecked: props.user.recordingEnabled
        }
        this.onCheckChanged = this.onCheckChanged.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (typeof nextProps.user != "undefined") {
            this.setState({
                user: nextProps.user,
                isChecked: nextProps.user.recordingEnabled
            });
        }
    }
    
    onCheckChanged(checked) {
        this.setState({ isChecked: checked });
        this.props.onValueChanged(this.props.user.id, checked);
    }
    
    render() {
        var addCheckbox = <div><Switch id={this.state.objectId} onChange={this.onCheckChanged} checked={this.state.isChecked} /></div>;
        return (
            <div className="divTableRow" key={this.state.user.id}>
                <div className="divTableCell column1">
                    {addCheckbox}
                </div>
                <div className="divTableCell column2">
                    {this.state.user.displayName}<br />
                    <sub>{this.state.user.mail !== null ? this.state.user.mail : this.state.user.userPrincipalName}</sub>
                </div>
                <div className="divTableCell column3">&nbsp;</div>
            </div>
        );

    }
}

export { ChatUser }