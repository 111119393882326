import React, { Component } from 'react';
import { Route, Switch, useHistory } from 'react-router';
//import { Layout } from './components/Layout';
import { Home } from './components/Home';
//import { FetchData } from './components/FetchData';
//import { Counter } from './components/Counter';
import { Login } from './components/Login';
import { NewPolicy } from './components/NewPolicy';
import { AddUsers } from './components/AddUsers';
import { UserSettings } from './components/UserSettings';
import { TenantSettings } from './components/TenantSettings';
import { RemoveUsers } from './components/RemoveUsers';
import { FinishWizard } from './components/FinishWizard';
import { RemovePolicy } from "./components/RemovePolicy";
import axios from "axios";
import { Ring } from "react-awesome-spinners";
import './custom.css'
import {ChatSettings} from "./components/ChatSettings";
import {ChatConsent} from "./components/ChatConsent";
import {AdSyncConsent} from "./components/AdSyncConsent";
import {AdSyncSettings} from "./components/AdSyncSettings";

export default class App extends Component {
    static displayName = App.name;
    constructor(props) {
        super(props);
        this.state = ({
            portal: "", //"dev",
            authId: "", //"P1342366478",
            authToken: "", //"R5xQ83yeQShEtbiGhS1K",
            portalCode: "", //"dev",
            accessToken: "",
            accountSlug: "", //"fastcs",
            users: [],
            step7visible: false,
            step8visible: false,
            step9visible: false,
            stepRemoveVisible: false,
            tenantId: "", //"40019ce4-03fc-4186-8669-baee9f416367",
            domainName: "",
            botA_id: "", //"261a0176-e1f9-45db-be35-a7a97ba56b6e",
            botB_id: "", //"40b88eba-6ae1-4ca2-9341-7899bf94794a",
            alwaysAllowCalls: true,
            playAnnouncements: true,
            recordVideo: false,
            chatRecordingAppId: ""
        });

        this.onLogin = this.onLogin.bind(this);
        this.cancelStep8 = this.cancelStep8.bind(this);
        this.cancelStep9 = this.cancelStep9.bind(this);
        this.logout = this.logout.bind(this);
        this.updatePlayAnnouncements = this.updatePlayAnnouncements.bind(this);
    }

    onLogin(portalCode, accountSlug, authId, authToken, botA, botB, accessToken, tenantId, domainName, config, accountType, chatRecordingAppId, adSyncAppId, convenienceBot) {
        this.setState({
            portalCode: portalCode,
            accountSlug: accountSlug,
            authId: authId,
            authToken: authToken,
            step3visible: true,
            botA_id: botA,
            botB_id: botB,
            convenienceBot: convenienceBot,
            accessToken: accessToken.accessToken,
            tokenExpiry: accessToken.accessTokenExpiresUtc,
            tenantId: tenantId,
            domainName: domainName,
            config: config,
            accountType: accountType,
            chatRecordingAppId: chatRecordingAppId,
            adSyncAppId: adSyncAppId
        });
    }

    updatePlayAnnouncements(checked) {
        this.setState({ playAnnouncements: checked });
    }

    cancelStep8(event) {
        event.preventDefault();
        this.setState({ step7visible: true, step8visible: false });
    }

    cancelStep9(event) {
        event.preventDefault();
        this.setState({ step8visible: true, step9visible: false });
    }

    onValueChanged(objectId, productCode) {
        var users = this.state.users;
        if (users) {
            var user = users.find(e => e.objectID === objectId).first();
            user.product = productCode;
            this.setState({ users: users });
        }
    }

    logout(event) {
        event.preventDefault();
        this.setState({ authToken: "" });
    }

    render() {
        if (this.state.authToken === "") {
            return <Login onLogin={this.onLogin} provisionUrl={this.props.provisionUrl} configurationUrl={this.props.configurationUrl} impersonateChildAccountUrl={this.props.impersonateChildAccountUrl}/>;
        }

        return (
            <main>
                <Switch>
                    <Route path="/" render={(props) => (
                        <Home
                            botA={this.state.botA_id}
                            botB={this.state.botB_id}
                            portal={this.state.portalCode}
                            tenantId={this.state.tenantId}
                            cancel={this.logout}
                        />)} exact />
                    <Route
                        path="/newpolicy"
                        render={(props) => (
                            <NewPolicy
                                botA={this.state.botA_id}
                                botB={this.state.botB_id}
                                convenienceBot={this.state.convenienceBot}
                                portalCode={this.state.portalCode}
                                slug={this.state.accountSlug}
                                domainName={this.state.domainName}
                                tenantId={this.state.tenantId}
                                accessToken={this.state.accessToken}
                                confirmConsentUrl={this.props.confirmConsentUrl}
                                updatePlayAnnouncements={this.updatePlayAnnouncements}
                                config={this.state.config}
                            />
                            )}
                    />
                    <Route 
                        path="/addusers"
                        render={(props) => (
                            <AddUsers processUsersUrl={this.props.processUsersUrl}
                                provisionUsersUrl={this.props.provisionUsersUrl}
                                portalCode={this.state.portalCode}
                                slug={this.state.accountSlug}
                                accessToken={this.state.accessToken}
                                tenantId={this.state.tenantId}
                                users={this.state.users}
                                accountType={this.state.accountType}
                                alwaysAllowCalls={this.state.alwaysAllowCalls}
                                playAnnouncements={this.state.playAnnouncements}
                            />
                            )}
                    />
                    <Route path="/removepolicy" render={(props) => (
                        <RemovePolicy
                            alwaysAllowCalls={this.state.alwaysAllowCalls}
                            playAnnouncements={this.state.playAnnouncements}
                            recordVideo={this.state.recordVideo}
                            botA={this.state.botA_id}
                            botB={this.state.botB_id}
                            portal={this.state.portalCode}
                            tenantId={this.state.tenantId}
                            domainName={this.state.domainName}
                        />)}
                    />
                    <Route path="/tenantsettings" render={(props) => (
                        <TenantSettings
                            tenantId={this.state.tenantId}
                            configurationUrl={this.props.configurationUrl}
                            createTenantSettingsUrl={this.props.createTenantSettingsUrl}
                            deleteTenantSettingsUrl={this.props.deleteTenantSettingsUrl}
                            portalCode={this.state.portalCode}
                            slug={this.state.accountSlug}
                            accessToken={this.state.accessToken}
                        />)}
                    />
                    <Route path="/usersettings" render={(props) => (
                        <UserSettings
                            tenantId={this.state.tenantId}
                            configurationUrl={this.props.configurationUrl}
                            portalCode={this.state.portalCode}
                            slug={this.state.accountSlug}
                            accessToken={this.state.accessToken}
                        />)}
                    />
                    <Route path="/removeusers" render={(props) => (
                        <RemoveUsers
                            tenantId={this.state.tenantId}
                            configurationUrl={this.props.configurationUrl}
                            removeUserConfigUrl={this.props.removeUserConfigUrl}
                            portalCode={this.state.portalCode}
                            slug={this.state.accountSlug}
                            domainName={this.state.domainName}
                            accessToken={this.state.accessToken}
                        />)}
                    />
                    <Route path="/chatconsent" render={(props) => (
                        <ChatConsent
                            consentAppId={this.state.chatRecordingAppId}
                            confirmConsentUrl={this.props.confirmConsentUrl}
                            portal={this.state.portalCode}
                            tenantId={this.state.tenantId}
                            accessToken={this.state.accessToken}
                            slug={this.state.accountSlug}
                            navigation={this.props.navigation}
                        />)}
                    />
                    <Route path="/chatsettings" render={(props) => (
                        <ChatSettings
                            aadUsersUrl={this.props.aadUsersUrl}
                            teamsChannelsUrl={this.props.teamsChannelsUrl}
                            tenantId={this.state.tenantId}
                            portalCode={this.state.portalCode}
                            slug={this.state.accountSlug}
                            configurationUrl={this.props.configurationUrl}
                            accessToken={this.state.accessToken}
                            chatRecordingAppId={this.state.chatRecordingAppId}
                        />
                    )}
                    />
                    <Route path="/adsyncconsent" render={(props) => (
                        <AdSyncConsent
                            consentAppId={this.state.adSyncAppId}
                            confirmConsentUrl={this.props.confirmConsentUrl}
                            portal={this.state.portalCode}
                            tenantId={this.state.tenantId}
                            accessToken={this.state.accessToken}
                            slug={this.state.accountSlug}
                            navigation={this.props.navigation}
                        />)}
                    />
                    <Route path="/adsyncsettings" render={(props) => (
                        <AdSyncSettings
                            aadGroupsUrl={this.props.aadGroupsUrl}
                            productCodeValidationUrl={this.props.productCodeValidationUrl}
                            availableProductCodesUrl={this.props.availableProductCodesUrl}
                            tenantId={this.state.tenantId}
                            portalCode={this.state.portalCode}
                            slug={this.state.accountSlug}
                            adSyncUrl={this.props.adSyncUrl}
                            domainName={this.state.domainName}
                            accessToken={this.state.accessToken}
                            authId={this.state.authId}
                            authToken={this.state.authToken}
                            accountType={this.state.accountType}
                        />
                    )}
                    />
                    <Route path="/finish" render={(props) => (
                        <FinishWizard
                            portalCode={this.state.portalCode}
                        />)}
                    />
                </Switch>
            </main>
        );
    }
}

