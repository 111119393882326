import React from 'react';
import Switch from "react-switch"

class ChatChannel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            objectId: props.channel.id,
            channel: props.channel,
            isChecked: props.channel.recordingEnabled
        }
        this.onCheckChanged = this.onCheckChanged.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (typeof nextProps.channel != "undefined") {
            this.setState({
                channel: nextProps.channel,
                isChecked: nextProps.channel.recordingEnabled
            });
        }
    }

    onCheckChanged(checked) {
        this.setState({ isChecked: checked });
        
        if (this.props.onValueChanged) {
            this.props.onValueChanged(this.props.channel.id, checked);
        }
    }

    render() {
        var addCheckbox = <div><Switch id={this.state.objectId} onChange={this.onCheckChanged} checked={this.state.isChecked} /></div>;
        return (
            <div className="divTableRow" key={this.state.channel.id}>
                <div className="divTableCell column1">
                    {addCheckbox}
                </div>
                <div className="divTableCell column2">
                    {this.state.channel.displayName}<br/>
                    <sub>{this.state.channel.description}</sub>
                </div>
                <div className="divTableCell column3">&nbsp;</div>
            </div>
        );
    }
}

export { ChatChannel }