import React from "react";
import {ChatUser} from "./ChatUser";
import {AlertModal} from "./AlertModal";

class ChoiceModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            isFiltered: true,
            filter: null,
            selectedValue: null,
            displayOptions: null,
            moreOptions: null
        }

        this.selectedOptionChanged = this.selectedOptionChanged.bind(this);
        this.load = this.load.bind(this);
        this.closeAlertModal = this.closeAlertModal.bind(this);
    }
    
    componentDidMount() {
        this.setState({
            visible: this.props.Visible,
            filter: this.props.Filter,
            selectedValue: null,
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            visible: this.props.Visible,
            filter: this.props.Filter,
            selectedValue: null,
        }, this.load);
    }
    
    load() {
        if (this.props.Options === null) return;
        
        let displayOptions = null;
        let moreOptions = null;
        let regexPattern = new RegExp(this.state.filter, 'i');

        const filteredList = this.props.Options !== null 
            ? this.props.Options.filter(x => regexPattern.test(x.text)).sort((a,b) => (a.value > b.value) ? 1 : -1)
            : [];

        const unFilteredList = this.props.Options !== null 
            ? this.props.Options.sort((a,b) => (a.value > b.value) ? 1 : -1) 
            : [];

        if (filteredList !== null && this.state.isFiltered === true && filteredList.length > 0) {
            if (this.state.selectedValue === null) this.setState({selectedValue: filteredList[0].value});
            displayOptions = filteredList.map((option) => {
                return <option value={option.value} key={option.value}>{option.text}</option>
            });
            moreOptions = <option value="" key="more">Show All</option>
        }
        else if (unFilteredList !== null && unFilteredList.length > 0) {
            if (this.state.selectedValue === null) this.setState({selectedValue: unFilteredList[0].value});
            displayOptions = unFilteredList.map((option) => {
                return <option value={option.value} key={option.value}>{option.text}</option>
            });
            moreOptions = null;
        }

       if (unFilteredList !== null && unFilteredList.length === 0 && this.state.visible === true){        
            if (this.props.OnNoItems)
                this.props.OnNoItems();
        }
        
        this.setState({
            displayOptions: displayOptions,
            moreOptions: moreOptions
        });
    }

    selectedOptionChanged(value) {
        if (value === ""){
            this.setState({
                isFiltered: false
            }, this.load)
        }
        else {
            this.setState({
                selectedValue: value
            }, this.load)
        }
    }
    
    closeAlertModal() {
        this.setState({isFiltered: true, alertModalVisible: false}, this.load);
    }

    render() {
        return (
            <div className="modal" style={{visibility: this.state.visible === true ? 'visible' : 'hidden' }}>
                <div className="modalHeader">
                    {this.props.Title}
                </div>
                <div className="modalBody">
                    <div className="modalBodyText">{this.props.BodyText}</div>
                    <span className="modalChoiceText">{this.props.ChoiceText}</span> 
                    <select id="portalList"
                            onChange={(e) => {this.selectedOptionChanged(e.target.value)}}
                            className="modalChoiceDropDown"
                            value={this.state.selectedValue === null ? '' : this.state.selectedValue}
                    >
                        {this.state.displayOptions}
                        {this.state.moreOptions}
                    </select>
                </div>
                <div className="modalFooter">
                    <button onClick={() => {
                        this.setState({
                            visible: false,
                            alertModalVisible: false,
                            isFiltered: true
                        });
                        if (this.props.OnCancel)
                            this.props.OnCancel();
                    }} className="muted-link mr-1" >Cancel</button>
                    <button onClick={() => {
                        this.setState({
                            visible: false,
                            alertModalVisible: false,
                            isFiltered: true
                        });
                        if (this.props.OnOk)
                            this.props.OnOk(this.state.selectedValue);
                    }} className="next-link" >Ok</button>
                </div>
            </div>
        )
    }


}

export { ChoiceModal }
