import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import {Link, Redirect} from 'react-router-dom';
import logo from './DubberMSTeamsSmall.png';
import infoicon from './InfoIcon.png';
import cross from './RedCross.png'
import questionMark from './BlueQuestionMark.png'
import tick from './TransparentTick.png'
import axios from "axios";
import { Ring } from "react-awesome-spinners";

class AdSyncConsent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            consentConfirmed: false,
            loading: false,
            errorMessage: "",
            consentChecked: false,
            doRedirect: false,
        };

        this.confirmConsent = this.confirmConsent.bind(this);
    }

    componentDidMount() {
        this.confirmConsent();
    }

    confirmConsent(event) {
        this.setState({ loading: true });
        
        if (event !== undefined)
            event.preventDefault();
        
        let config = {
            headers: { 'Authorization': 'Jwt ' + this.props.accessToken }
        };
        let body = {
            portal: this.props.portal,
            slug: this.props.slug,
            tenantId: this.props.tenantId,
            type: 'adsync'
        };
        this.setState({
            errorMessage: ""
        });
        axios.post(this.props.confirmConsentUrl, body, config)
            .then(taskResponse => {
                this.setState({
                    loading: false,
                    consentConfirmed: taskResponse.data.adSyncConsentConfirmed,
                    consentChecked: true,
                    doRedirect: event === undefined && taskResponse.data.adSyncConsentConfirmed
                });
            }).catch(error => {
                this.setState({
                    errorMessage: error.data,
                    loading: false,
                    consentConfirmed: false,
                    consentChecked: false,
                    doRedirect: false
                });
        });
    }

    render() {
        if (this.state.doRedirect === true && this.state.consentConfirmed === true){
            return <Redirect to='/adsyncsettings'/>
        }
        
        var consentLink = "https://login.microsoftonline.com/" + this.props.tenantId + "/adminconsent?client_id=" + this.props.consentAppId;
        var consentTick = this.state.consentConfirmed ? <img className="green-tick" src={tick} /> : <img className="green-tick" src={cross} />;
        if (!this.state.consentChecked) {
            consentTick = <img className="green-tick" src={questionMark} />;
        }
        return (
            <div>
                <div className="ui center aligned container head">
                    <div className="stepheader">
                        <div className="step">Step 1</div>
                        <img src={logo} alt="Dubber MS Teams" className="small-logo" />
                        <div className="step-back"><NavLink tag={Link} to="/">&lt; Back</NavLink></div>
                    </div>
                    <div className="ui aligned container start">
                        <div className="step-header">
                            <h3>Consent Permissions for AD Sync</h3>
                        </div>
                        <div className="instructions">
                            <img src={infoicon} alt="i" />
                            <br />
                            <p><b>You must consent permissions for the Bots to regularly syncronise your directory.</b></p>
                            <p>We require consent to the following permissions to enable the active directory sync:
                            </p>
                            <ul>
                                <li>Read user list</li>
                                <li>Read group list</li>
                            </ul>
                            <p>The links will open in a new tab and the credentials used to consent the permissions must have the role Global Admin</p>
                        </div>
                        <div className="column-half">
                            <div className="space20px">&nbsp;
                            </div>
                            <div className="space20px">
                                <h4>Step 1</h4>
                                <a href={consentLink} className="download" target="botA" rel="noopener noreferrer">Consent permissions for Ad Sync</a>{consentTick}
                            </div>
                        </div>
                        <label id="errorMessageLabel" className="errorText">{this.state.errorMessage}</label>
                    </div>
                    <div className={!this.state.consentConfirmed ? "link" : "hidden-wizard-step"}>
                        <button onClick={this.confirmConsent} className="next-link" >Confirm</button>
                    </div>
                    <div className={this.state.consentConfirmed ? "link" : "hidden-wizard-step"}>
                        <div><NavLink tag={Link} to="/adsyncsettings">Next</NavLink></div>
                    </div>
                    <div className={this.state.loading ? "spinner-div" : "spinner-hidden"}>
                        <div className="middle-of-page">
                            <Ring />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export { AdSyncConsent }